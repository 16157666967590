export default function Footer(props) {
  // return (<div></div>);
  return (
    <div className="footer p-4 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <img
              src="./images/malaeb.png"
              className="img-fluid mx-auto d-block"
              alt="img"
            />
            <p className="text-center">Download the App Now</p>
            <p className="text-center">
              <a href="https://play.google.com/store/apps/details?id=com.malaebapp.app">
                <img
                  src="./images/googleplay.png"
                  className="me-2 imgSocial"
                  alt="img"
                />
              </a>
              <a href="https://itunes.apple.com/us/app/malaeb-app/id1094201670?ls=1&mt=8">
                <img
                  src="./images/appstore.png"
                  className="imgSocial"
                  alt="img"
                />
              </a>
            </p>
            <p className="text-center pt-3">
              <a href="/#">
                <img src="./images/facebook.png" className="me-3" alt="img" />
              </a>
              <a href="/#">
                <img src="./images/twitter.png" className="me-3" alt="img" />
              </a>
              <a href="/#">
                <img src="./images/instagram.png" alt="img" />
              </a>
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 ps-lg-5 willHide">
            <h6 className="pt-4">About Malaeb</h6>
            <p>
              <a href="/#">About Us</a>
            </p>
            <p>
              <a href="/#">Terms and Condition</a>
            </p>
            <p>
              <a href="/#">How it works</a>
            </p>
            <p>
              <a href="/#">Careers</a>
            </p>
            <p>
              <a href="/#">Contact Us</a>
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 willHide">
            <h6 className="pt-4">Malaeb For players</h6>
            <p>
              <a href="/#">Stadiums List</a>
            </p>
            <p>
              <a href="/#">Matches List</a>
            </p>
            <p>
              <a href="/#">FAQs</a>
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 willHide">
            <h6 className="pt-4">Malaeb For Stadium owners</h6>
            <p>
              <a href="/#">Join as an owner</a>
            </p>
            <p>
              <a href="/#">Terms and Condition</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
