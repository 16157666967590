import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { update_selected_slot } from '../../../redux/ducks/malaeb';
import { SERVER_URL, SPORTS } from '../../../Constants';
import axios from 'axios';
import { store } from 'react-notifications-component';

let debouncedCallback;

export function ModalBooking({
  selected_stadium,
  filters,
  changeFilter,
  isFemaleDay,
}) {
  const dispatch = useDispatch();
  const { SelectedSlot, user_data } = useSelector((state) => state.malaeb);

  const [pitchesList, setPitchesList] = useState([]);
  let selected_date = moment(filters.date, 'YYYY-MM-DD');
  const show_format = selected_date.format('ddd DD MMM YYYY');

  function setSelectedSlot(slot, pitch = null) {
    slot.duration = filters.duration;
    slot.date = filters.date;
    slot.final_price = slot.price_after_discount || slot.price;
    if (pitch) {
      slot.pitch = pitch;
      slot.upfront_price = pitch.upfront
        ? pitch.upfront[`price_${slot.duration}`]
        : 0;
    }
    dispatch(update_selected_slot(slot));
  }

  async function getAvailableSlots() {
    setPitchesList([]);
    let requests_list = [];
    selected_stadium.pitches.forEach((item) => {
      let passed_obj = {
        stadium_id: selected_stadium.id,
        date: filters.date,
        duration: filters.duration,
        pitch_id: item.id,
      };
      let rr = axios
        .post(`${SERVER_URL}/api/v2/stadiums/timeframe`, passed_obj)
        .then((res) => {
          let pitch_timeslots = res.data.data;
          // pitches_list.push({pitch: item, slots:pitch_timeslots});
          let morning_shift = [],
            night_shift = [];
          for (let slot of pitch_timeslots.slots_status) {
            if (slot.time < '12:00:00' && slot.status == 0)
              morning_shift.push(slot);
            else if (slot.status == 0) night_shift.push(slot);
          }
          return {
            pitch: item,
            slots: pitch_timeslots,
            morning_shift,
            night_shift,
          };
        })
        .catch((error) => {
          if (error.response) {
            console.error(error.response.data);
            // window.alert(error.response.data.error);
            // window.location.reload();
            return { pitch: item, error: error.response.data.error };
          } else console.error(error);
        });
      requests_list.push(rr);
    });
    await Promise.all(requests_list).then(function (values) {
      console.log({ values });
      setPitchesList(values);
    });
  }

  useEffect(() => {
    setSelectedSlot({});
    // let pitches_list = [];

    if (debouncedCallback) clearTimeout(debouncedCallback);
    debouncedCallback = setTimeout(getAvailableSlots, 500);
    // console.log({pitches_list2:pitches_list});
  }, [filters]);

  const today = moment().format('YYYY-MM-DD');
  selected_date = moment(filters.date, 'YYYY-MM-DD');
  const diff = selected_date.diff(today, 'days');

  if (diff >= selected_stadium.max_date_for_advanced_bookings || diff < 0) {
    console.log('something is wrong with the date');
    changeFilter('date', today);
  }

  function generated_date(next_day = true) {
    if (next_day) selected_date.add(1, 'days');
    else selected_date.subtract(1, 'days');

    if (next_day == false && today == filters.date) {
      store.addNotification({
        title: 'Warning!!',
        message: `you can't book a pitch in the past`,
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      return;
    }
    if (
      next_day &&
      selected_date.diff(today, 'days') >=
        selected_stadium.max_date_for_advanced_bookings
    ) {
      store.addNotification({
        title: 'Warning!!',
        message: `You can only book ${selected_stadium.max_date_for_advanced_bookings} days in advance for this Stadiums`,
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      return;
    }

    let filter_format = selected_date.format('YYYY-MM-DD');
    // let show_format = selected_date.format('ddd DD MMM YYYY');
    changeFilter('date', filter_format);
    // return show_format;
  }

  function generated_days_list(days_limit = 14) {
    let dates_list = [];
    for (let i = 0; i < days_limit; i++) {
      let day_after = moment().add(i, 'days');
      dates_list.push({
        date_format: day_after.format('YYYY-MM-DD'),
        month_day: day_after.format('DD MMM'),
        day_name: i == 0 ? 'Today' : day_after.format('ddd'),
      });
    }
    return dates_list;
  }
  const dates_list = generated_days_list(
    selected_stadium.max_date_for_advanced_bookings
  );

  function bookNow() {
    window.$('#bookingModal').modal('hide');
    // window.$("#PaymentMethods").modal('show');
    if (!user_data.id) window.$('#VerifyNumberModal').modal('show');
    else window.$('#PaymentMethods').modal('show');
  }

  //Book Modal
  return (
    <div
      className="modal modalSlot fade"
      id="bookingModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title text-center w-100">
              Book a Field on {selected_stadium.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body container">
            <div className="row">
              <div className="col-lg-6">
                <p className="fw-bold mb-1">Select a Date</p>
                {/* Hide in mobile Display only in Desktop */}
                <div className="dateSlot d-none d-lg-block">
                  <div className="row">
                    <div className="col-lg-3">
                      <button
                        className="btn border-0 px-0"
                        onClick={(evt) => generated_date(false)}
                      >
                        <img src="./images/Angle-left.png" alt="img" />
                        Past day
                      </button>
                    </div>
                    <div className="col-lg-6 p-0">
                      <p className="textG mb-2 text-center pt-2">
                        {show_format}
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <button
                        className="btn border-0 px-0 float-end"
                        onClick={(evt) => generated_date(true)}
                      >
                        Next day
                        <img src="./images/Angle-right.png" alt="img" />
                      </button>
                    </div>
                  </div>
                </div>
                {/* Hide in desktop Display only in Mobile */}
                <div className="d-flex overflow-auto d-lg-none">
                  {dates_list.map((item, key2) => (
                    <button
                      className={`btn btnDay me-2 mb-3 ${
                        item.date_format == filters.date ? 'btnActive' : ''
                      }`}
                      key={key2}
                      onClick={(evt) => {
                        changeFilter('date', item.date_format);
                      }}
                    >
                      <p className="mb-0 text-center">{item.day_name}</p>
                      <p className="mb-0">{item.month_day}</p>
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-lg-6">
                <p className="fw-bold mb-1 d-none d-md-block"> Duration</p>
                <p className="fw-bold mb-1 pt-2 d-md-none">Select a Duration</p>
                <div className="row pb-4 btnSlot" dir="ltr">
                  {selected_stadium.duration_options.includes(60) && (
                    <div className="col">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-outlined"
                        id="success-outlined"
                        autoComplete="off"
                      />
                      <label
                        className={`btn btn-outline-success w-100 ${
                          filters.duration == 60 ? 'active' : ''
                        }`}
                        htmlFor="success-outlined"
                        onClick={(evt) => changeFilter('duration', 60)}
                      >
                        60 mins
                      </label>
                    </div>
                  )}
                  {selected_stadium.duration_options.includes(90) && (
                    <div className="col">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-outlined"
                        id="success-outlined2"
                        autoComplete="off"
                      />
                      <label
                        className={`btn btn-outline-success w-100 ${
                          filters.duration == 90 ? 'active' : ''
                        }`}
                        htmlFor="success-outlined2"
                        onClick={(evt) => changeFilter('duration', 90)}
                      >
                        90 mins
                      </label>
                    </div>
                  )}
                  {selected_stadium.duration_options.includes(120) && (
                    <div className="col">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-outlined"
                        id="success-outlined3"
                        autoComplete="off"
                      />
                      <label
                        className={`btn btn-outline-success w-100 ${
                          filters.duration == 120 ? 'active' : ''
                        }`}
                        htmlFor="success-outlined3"
                        onClick={(evt) => changeFilter('duration', 120)}
                      >
                        120 mins
                      </label>
                    </div>
                  )}
                </div>
              </div>
              {isFemaleDay && (
                <div className="container">
                  {/* <h4 className="text-center hasFemaleDays">Female Day</h4> */}
                  <img
                    src="./images/femaleimg.jpg"
                    className="rounded w-100 mb-2"
                    alt="img"
                  />
                </div>
              )}
            </div>
            <h6 className="fw-bold pb-sm-0 pt-md-3 pb-md-2">Available Slots</h6>
            {/* Hide in mobile Display only in Desktop */}
            <div className="d-none overflow-auto overflowSlots d-md-flex mb-3">
              {pitchesList[0]?.error ? (
                <div className="w-100 flex flex-column">
                  <h3 className="text-center flex text-capitalize text-muted py-5">
                    {pitchesList[0].error}
                  </h3>
                </div>
              ) : (
                pitchesList.length > 0 &&
                pitchesList?.map(
                  ({ pitch, morning_shift, night_shift }, key) => {
                    if (pitch.sports.length === 0) pitch.sports.push(SPORTS[1]);
                    return (
                      <div className="me-3 w-100" key={key}>
                        <div className="availableSlots p-2">
                          <img
                            src={`./images/sports/${pitch.sports[0].id}.png`}
                            width="30"
                            alt="img"
                          />
                          <span className="textG mx-2">
                            {' '}
                            {pitch.pitch_size} V {pitch.pitch_size}
                          </span>
                          <span>{pitch.lable}</span>
                        </div>
                        <div>
                          <div className="row px-3">
                            {morning_shift && morning_shift.length > 0 && (
                              <div className="col-md p-1">
                                <p className="mb-2 textSmall">Morning slots</p>
                                {morning_shift.map((item, key2) => (
                                  <button
                                    className={`btn 
                                                      ${
                                                        item.is_female_slot
                                                          ? 'femaleSlot minSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                      ${
                                                        item.is_lowest_price
                                                          ? 'specialOfferLower minSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                      ${
                                                        item.is_special_offer
                                                          ? 'specialOffer minSpecial newSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                          ${
                                                            !item.is_special_offer &&
                                                            !item.is_lowest_price &&
                                                            !item.is_female_slot
                                                              ? 'btnTimeS w-100 p-3 mb-2'
                                                              : ''
                                                          } 
                                                          ${
                                                            SelectedSlot.time ==
                                                              item.time &&
                                                            SelectedSlot.pitch_id ==
                                                              item.pitch_id
                                                              ? 'active'
                                                              : ''
                                                          }	
                                                      `}
                                    key={key2}
                                    onClick={(evt) =>
                                      setSelectedSlot(item, pitch)
                                    }
                                  >
                                    {item.is_female_slot ? (
                                      <p className="mb-1">For Ladies</p>
                                    ) : item.is_special_offer ? (
                                      <p className="mb-1">Special Offer</p>
                                    ) : item.is_lowest_price ? (
                                      <p className="mb-1">Lowest Price</p>
                                    ) : (
                                      ''
                                    )}
                                    <p className="fw-bold mb-1">
                                      {item.time_am_pm} <br />
                                      {item.price_after_discount ||
                                        item.price}{' '}
                                      {selected_stadium.currency}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            )}

                            {night_shift && night_shift.length > 0 && (
                              <div className="col-md p-1">
                                <p className="mb-2 textSmall">Evening slots</p>
                                {night_shift.map((item, key2) => (
                                  <button
                                    className={`btn 
                                                      ${
                                                        item.is_female_slot
                                                          ? 'femaleSlot minSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                      ${
                                                        item.is_lowest_price
                                                          ? 'specialOfferLower minSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                      ${
                                                        item.is_special_offer
                                                          ? 'specialOffer minSpecial newSpecial btnTimeS w-100 mb-2 p-0'
                                                          : ''
                                                      } 
                                                          ${
                                                            !item.is_special_offer &&
                                                            !item.is_lowest_price &&
                                                            !item.is_female_slot
                                                              ? 'btnTimeS w-100 p-3 mb-2'
                                                              : ''
                                                          } 
                                                          ${
                                                            SelectedSlot.time ==
                                                              item.time &&
                                                            SelectedSlot.pitch_id ==
                                                              item.pitch_id
                                                              ? 'active'
                                                              : ''
                                                          }	
                                                      `}
                                    key={key2}
                                    onClick={(evt) =>
                                      setSelectedSlot(item, pitch)
                                    }
                                  >
                                    {item.is_female_slot ? (
                                      <p className="mb-1">For Ladies</p>
                                    ) : item.is_special_offer ? (
                                      <p className="mb-1">Special Offer</p>
                                    ) : item.is_lowest_price ? (
                                      <p className="mb-1">Lowest Price</p>
                                    ) : (
                                      ''
                                    )}
                                    <p className="fw-bold mb-1">
                                      {item.time_am_pm} <br />
                                      {item.price_after_discount ||
                                        item.price}{' '}
                                      {selected_stadium.currency}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              )}
            </div>

            <div className="flex">
              {pitchesList[0]?.error ? (
                <h3 className="d-block d-md-none text-center flex text-capitalize text-muted py-5">
                  {pitchesList[0].error}
                </h3>
              ) : (
                pitchesList.length > 0 &&
                pitchesList?.map(
                  ({ pitch, morning_shift, night_shift }, key) => {
                    return (
                      <div key={key}>
                        {/* Hide in desktop Display only in Mobile */}
                        <div className="availableSlots p-2 d-block w-100 d-md-none">
                          <img
                            src={`./images/sports/${pitch.sports[0].id}.png`}
                            width="30"
                            alt="img"
                          />
                          <span className="textG mx-2">
                            {' '}
                            {pitch.pitch_size} V {pitch.pitch_size}
                          </span>
                          <span>{pitch.lable}</span>
                        </div>

                        <div>
                          <div className="row">
                            {morning_shift && morning_shift.length > 0 && (
                              <div className="d-flex overflow-auto d-md-none pb-2 px-2">
                                {morning_shift.map((item, key2) => (
                                  <button
                                    className={`btn 
                                                              ${
                                                                item.is_lowest_price
                                                                  ? 'specialOfferLower minSpecial me-2 w-100 p-0 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                item.is_special_offer
                                                                  ? 'specialOffer minSpecial newSpecial me-2 w-100 p-0 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                !item.is_special_offer &&
                                                                !item.is_lowest_price
                                                                  ? 'btnTimeS me-2 w-100 px-3 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                SelectedSlot.time ==
                                                                  item.time &&
                                                                SelectedSlot.pitch_id ==
                                                                  item.pitch_id
                                                                  ? 'active'
                                                                  : ''
                                                              }	
                                                          `}
                                    key={key2}
                                    onClick={(evt) =>
                                      setSelectedSlot(item, pitch)
                                    }
                                  >
                                    {item.is_special_offer ? (
                                      <p className="mb-1">Special Offer</p>
                                    ) : item.is_lowest_price ? (
                                      <p className="mb-1">Lowest Price</p>
                                    ) : (
                                      ''
                                    )}
                                    <p className="fw-bold mb-1">
                                      {item.time_am_pm}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            )}
                            {night_shift && night_shift.length > 0 && (
                              <div className="d-flex overflow-auto d-md-none pb-2">
                                {night_shift.map((item, key2) => (
                                  <button
                                    className={`btn 
                                                              ${
                                                                item.is_lowest_price
                                                                  ? 'specialOfferLower minSpecial me-2 w-100 p-0 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                item.is_special_offer
                                                                  ? 'specialOffer minSpecial newSpecial me-2 w-100 p-0 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                !item.is_special_offer &&
                                                                !item.is_lowest_price
                                                                  ? 'btnTimeS me-2 w-100 px-3 mb-2'
                                                                  : ''
                                                              } 
                                                              ${
                                                                SelectedSlot.time ==
                                                                  item.time &&
                                                                SelectedSlot.pitch_id ==
                                                                  item.pitch_id
                                                                  ? 'active'
                                                                  : ''
                                                              }	
                                                          `}
                                    key={key2}
                                    onClick={(evt) =>
                                      setSelectedSlot(item, pitch)
                                    }
                                  >
                                    {item.is_special_offer ? (
                                      <p className="mb-1">Special Offer</p>
                                    ) : item.is_lowest_price ? (
                                      <p className="mb-1">Lowest Price</p>
                                    ) : (
                                      ''
                                    )}
                                    <p className="fw-bold mb-1">
                                      {item.time_am_pm}
                                    </p>
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              )}
            </div>
          </div>
          {/* Hide in mobile Display only in Desktop */}
          {SelectedSlot.pitch_id && (
            <>
              <div className="modal-footer d-none d-md-block">
                <div className="row w-100 justify-content-center">
                  <div className="col-lg-8">
                    <button
                      className="btn btnFind w-100 fw-bolder my-2"
                      onClick={(evt) => bookNow()}
                    >
                      Book Now (
                      <span className="float-right">
                        {SelectedSlot.price_after_discount > 0 && (
                          <del className="mr-1 text-muted">
                            <span className="amount">{SelectedSlot.price}</span>
                          </del>
                        )}
                        <span className="amount mx-1">
                          {SelectedSlot.price_after_discount ||
                            SelectedSlot.price}
                        </span>
                      </span>
                      {selected_stadium.currency})
                    </button>
                  </div>
                </div>
              </div>
              {/* Hide in desktop Display only in Mobile */}
              <div className="modal-footer d-block d-md-none">
                <div className="row w-100 justify-content-center">
                  <div className="col-6">
                    {/* <p className="h1">{SelectedSlot.price_after_discount || SelectedSlot.price} {selected_stadium.currency}</p> */}
                    <p className="h1">
                      {' '}
                      {SelectedSlot.price_after_discount > 0 && (
                        <del className="mr-1 text-muted">
                          <span className="amount">{SelectedSlot.price}</span>
                        </del>
                      )}
                      <span className="amount mx-1">
                        {SelectedSlot.price_after_discount ||
                          SelectedSlot.price}
                      </span>{' '}
                      {selected_stadium.currency}
                    </p>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btnFind w-100"
                      onClick={(evt) => bookNow()}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
