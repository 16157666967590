import { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_URL } from '../../../Constants';
import { setUserData } from '../../../redux/ducks/malaeb';
import { handleError } from '../../../helpers';
import { store } from 'react-notifications-component';

let counter_obj;

export function ModalValidateNumber({
  selected_stadium,
}) {
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [OtpCode, setOtpCode] = useState('');
  const dispatch = useDispatch();
  const { user_uuid, user_data } = useSelector((state) => state.malaeb);
  const [IsNewUser, setIsNewUser] = useState(false);
  const [NewUserEmail, setNewUserEmail] = useState('');

  function send_otp() {
    axios
      .post(`${SERVER_URL}/api/v2/login-by-phone/register`, {
        phone_number:
          selected_stadium.location.country_phone_code + PhoneNumber,
        uuid: user_uuid,
      })
      .then((res) => {
        if (res.data.status == 1) {
          if (res.data.roles[0] != 'player') {
            store.addNotification({
              title: 'Warning!!',
              message: `This isn't a player account, Please Use Malaeb Business app or use a different phone number.`,
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true,
              },
            });
            return;
          }
          if (res.data.is_new_user) setIsNewUser(true);
          setTimeout(() => {
            window.$('#sendCodeModal').modal('hide');
          }, 100);
          setTimeout(() => {
            window.$('#VerifyNumberModal').modal('hide');
          }, 100);
          setTimeout(() => {
            window.$('#sendCodeModal').modal('show');
          }, 300);
          seconds_counter();
        }
      })
      .catch(handleError);
  }

  function register_user() {
    console.log(user_data);
    axios
      .post(`${SERVER_URL}/api/v2/login-by-phone/verify`, {
        phone_number:
          selected_stadium.location.country_phone_code + PhoneNumber,
        uuid: user_uuid,
        code: OtpCode,
      })
      .then((register_res) => {
        // let {token, data:{email, id, name, phone_number, player, profile, roles, xp}} = register_res.data;
        // console.log({token, email, id, name, phone_number, player, profile, roles, xp});
        const token = register_res.data.token;
        dispatch(
          setUserData({ ...user_data, ...register_res.data.data, token })
        );

        if (IsNewUser && NewUserEmail) {
          const HEADER = {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          };
          // window.alert("you are a new user")
          axios
            .post(
              `${SERVER_URL}/api/v2/me`,
              { email: NewUserEmail, first_name: NewUserEmail },
              HEADER
            )
            .then((res) => {
              axios.get(`${SERVER_URL}/api/v2/me`, HEADER).then((user_res) => {
                console.log({ user_res });
                dispatch(
                  setUserData({ ...user_data, ...user_res.data.user, token })
                );
                passed_verification_and_show_payment();
              });

              // console.log("user profile updated");
              // refresh();
            })
            .catch((err) => {
              dispatch(setUserData({}));
              handleError(err);
            });
        } else passed_verification_and_show_payment();
      })
      .catch(handleError);
  }

  function passed_verification_and_show_payment() {
    window.$('#VerifyNumberModal').modal('hide');
    window.$('#sendCodeModal').modal('hide');
    setTimeout(() => {
      window.$('#PaymentMethods').modal('show');
    }, 300);
    if (counter_obj) clearInterval(counter_obj);
  }

  function seconds_counter() {
    if (counter_obj) clearInterval(counter_obj);
    let value = 60;
    counter_obj = setInterval(() => {
      if (value > 0) value = value - 1;
      else {
        value = '0';
        window.$('#sendCodeModal').modal('hide');
        setTimeout(() => {
          window.$('#VerifyNumberModal').modal('show');
        }, 300);
      }
      window.$('#seconds-counter').html(value);
    }, 1000);
  }

  function talk_customer_support() {
    window.$('.intercom-launcher').click();
  }

  function close_modal(show_booking_modal = false) {
    window.$('#VerifyNumberModal').modal('hide');
    window.$('#sendCodeModal').modal('hide');
    if (!show_booking_modal)
      setTimeout(() => {
        window.$('#VerifyNumberModal').modal('show');
      }, 300);
    else
      setTimeout(() => {
        window.$('#bookingModal').modal('show');
      }, 300);
  }

  return (
    <>
      {/* Modal verifiey Number*/}
      <div
        className="modal fade modalPhone"
        id="VerifyNumberModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0" dir="rtl">
              <h5
                className="modal-title w-100 text-center"
                id="exampleModalLabel"
              >
                ENTER YOUR PHONE NUMBER
              </h5>
              <button
                type="button"
                className="bg-white border-0"
                data-bs-dismiss="modal"
                onClick={(evt) => close_modal(true)}
              >
                <img src="./images/left.png" alt="img" />
              </button>
            </div>

            <div className="modal-body m-3">
              <img
                src="./images/register.png"
                className="img-fluid mx-auto d-block"
                alt="img"
              />
              <p className="text-center py-2">
                We will send you an SMS with <br />a one-time verfication code
              </p>
              <p className="mb-0 textVsmall">Mobile Number</p>
              <div className="input-group input-group-lg mb-5">
                <span className="input-group-text bg-white" id="basic-addon1">
                  +{selected_stadium.location.country_phone_code}
                </span>
                <input
                  type="number"
                  className="form-control"
                  aria-describedby="basic-addon1"
                  value={PhoneNumber}
                  onChange={(evt) => setPhoneNumber(evt.target.value)}
                />
              </div>

              <button
                className="btn btnFind secondModal w-100 my-2"
                onClick={(evt) => send_otp()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Send OTP */}
      <div
        className="modal fade"
        id="sendCodeModal"
        tabIndex={-1}
        aria-labelledby="sendCodeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0" dir="rtl">
              <h5
                className="modal-title w-100 text-center"
                id="exampleModalLabel"
              >
                OTP Verification
              </h5>
              <button
                type="button"
                className="bg-white border-0"
                data-bs-dismiss="modal"
                onClick={(evt) => close_modal()}
              >
                <img src="./images/left.png" alt="img" />
              </button>
            </div>
            <div className="modal-body">
              <img
                src="./images/register.png"
                className="img-fluid mx-auto d-block"
                alt="img"
              />

              <p className="text-center py-2">
                We Sent OTP verifications code, Please Verify within the next 60
                seconds
              </p>
              <div className="form-group mb-4">
                {IsNewUser && (
                  <div className="my-3">
                    <label htmlFor="user_email" className="w-100">
                      Your Email
                    </label>
                    <input
                      id="user_email"
                      className="form-control form-control-lg"
                      type="text"
                      name="email"
                      value={NewUserEmail}
                      onChange={(evt) => setNewUserEmail(evt.target.value)}
                      placeholder="example@test.com"
                    />
                  </div>
                )}

                <label htmlFor="otp" className="w-100">
                  OTP
                  <span className="float-end text-muted">
                    00:<span id="seconds-counter">59</span>
                  </span>
                  <a
                    className="float-end textVsmall text-muted clickable mx-3"
                    onClick={(evt) => send_otp()}
                  >
                    Resend again{' '}
                  </a>
                </label>
                <input
                  id="otp"
                  className="form-control form-control-lg"
                  type="text"
                  name="otp"
                  value={OtpCode}
                  onChange={(evt) => setOtpCode(evt.target.value)}
                />

                <a
                  className="textVsmall text-muted clickable"
                  onClick={(evt) => talk_customer_support()}
                >
                  Didn't get OTP ? Customer Support
                </a>
              </div>
              <button
                className="btn btnFind w-100 my-2"
                onClick={(evt) => register_user()}
              >
                {' '}
                Verify Number{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
