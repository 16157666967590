export default function ModalRefundRules(selected_stadium) {
  function close_modal() {
    window.$('#RefundRulesModal').modal('hide');
  }
  return (
    <>
      <div
        className="modal fade modalPhone"
        id="RefundRulesModal"
        tabIndex={-1}
        aria-labelledby="RefundRulesModalref"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0" dir="rtl">
              <h5
                className="modal-title w-100 text-center"
                id="RefundRulesModalref"
              >
                {selected_stadium.title} Refunding Rules
              </h5>
              <button
                type="button"
                className="bg-white border-0"
                data-bs-dismiss="modal"
                onClick={(evt) => close_modal()}
              >
                <img src="./images/left.png" alt="img" />
              </button>
            </div>

            <div className="modal-body m-3">
              <img
                src="./images/register.png"
                className="img-fluid mx-auto d-block"
                alt="img"
              />
              <div className="text-center py-2">
                <div>
                  <p dir="ltr">
                    If you can’t play because of coronavirus, you might be able
                    to get a refund or choose to rebook for another time.
                  </p>
                  <p dir="ltr">
                    You should check the website of the stadium you booked with
                    or any emails you’ve received from them. They might have a
                    coronavirus refund policy.
                  </p>
                  <p dir="ltr">
                    You have different rights depending on whether:
                  </p>
                  <ul>
                    <li dir="ltr">
                      <p dir="ltr">the play stadium cancelled your booking</p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        you want to cancel your booking but it’s still going
                        ahead
                      </p>
                    </li>
                  </ul>
                  <h2
                    dir="ltr"
                    id="h-if-the-stadium-cancelled-your-booking-nbsp-"
                    className="js-ref"
                  >
                    If the stadium cancelled your booking&nbsp;
                  </h2>
                  <p dir="ltr">
                    You’ll automatically have the right to a refund if your
                    booking was for:
                  </p>
                  <ul>
                    <li dir="ltr">
                      a package holiday -{' '}
                      <a href="/consumer/holiday-cancellations-and-compensation/claim-compensation-for-a-holiday/">
                        check if your booking counts as a package holiday and
                        how to get a refund
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      a flight leaving from the UK
                    </li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      a flight arriving in the UK with a UK or EU airline
                    </li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      a flight arriving in the EU with a UK airline
                    </li>
                  </ul>
                  <p dir="ltr">
                    <a href="/consumer/holiday-cancellations-and-compensation/if-your-flights-delayed-or-cancelled/">
                      Check how to get a refund if your flight’s cancelled.
                    </a>
                  </p>
                  <p dir="ltr">
                    If your booking was for something else, check the terms and
                    conditions to find out if you’re entitled to a refund and
                    how long it should take.
                  </p>
                  <h2
                    id="h-if-you-want-to-cancel-your-booking-but-it-s-still-going-ahead"
                    className="js-ref"
                  >
                    If you want to cancel your booking but it's still going
                    ahead
                  </h2>
                  <p dir="ltr">
                    You might not want or be able to play if for example:
                  </p>
                  <ul>
                    <li dir="ltr">you have to self-isolate</li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      you have to quarantine
                    </li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      coronavirus restrictions change in the place you’re
                      visiting
                    </li>
                  </ul>
                  <p>
                    If the stadium doesn’t cancel your booking, you can ask for
                    a refund but you don’t have an automatic right to one. Check
                    the terms and conditions of your booking to see what you can
                    do.
                  </p>
                  <p dir="ltr">
                    You can{' '}
                    <a
                      href="https://www.gov.uk/guidance/national-lockdown-stay-at-home"
                      rel="external"
                      className="a-external"
                    >
                      find out if your play is affected by coronavirus
                      restrictions
                    </a>{' '}
                    on GOV.UK.
                  </p>
                  <p dir="ltr">
                    You can also{' '}
                    <a
                      href="https://www.gov.uk/foreign-play-advice"
                      rel="external"
                      className="a-external"
                    >
                      check the foreign play advice on GOV.UK
                    </a>
                    .&nbsp;
                  </p>
                  <p dir="ltr">
                    Remember coronavirus restrictions and government advice
                    might change before you play or while you’re away.
                  </p>
                  <p dir="ltr">
                    Tell the stadium if the government&nbsp; say playling to a
                    country will put your health or safety at risk. This might
                    help you argue for a refund.
                  </p>
                  <p dir="ltr">
                    If the stadium won’t give you a refund, it’s usually better
                    to wait and see if they cancel the booking later. If they
                    cancel the booking, they should refund you.
                  </p>
                  <h2
                    dir="ltr"
                    id="h-if-you-re-offered-a-voucher-instead-of-a-refund"
                    className="js-ref"
                  >
                    If you’re offered a voucher instead of a refund
                  </h2>
                  <p dir="ltr">
                    It’s usually best to ask for a refund instead&nbsp;- you
                    should contact the stadium you booked with and ask. They
                    should give you a refund if they cancelled your play.
                  </p>
                  <p dir="ltr">
                    If you’re thinking of accepting a voucher, check the terms
                    and conditions, including:
                  </p>
                  <ul>
                    <li dir="ltr">when the voucher expires</li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      whether you can change your mind later and get a refund
                    </li>
                  </ul>
                  <h2
                    id="h-if-the-stadium-you-booked-with-has-gone-out-of-business"
                    className="js-ref"
                  >
                    If the stadium you booked with has gone out of business
                  </h2>
                  <p dir="ltr">
                    You should check if your booking will still go ahead - for
                    example, you could:
                  </p>
                  <ul>
                    <li dir="ltr">
                      <p dir="ltr">check the stadium’s website&nbsp;</p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        call or write to the stadium and ask&nbsp;&nbsp;
                      </p>
                    </li>
                  </ul>
                  <p dir="ltr">
                    If you booked with a play agent, you should contact the
                    other companies your play is with - for example, your flight
                    or hotel provider.
                  </p>
                  <p dir="ltr">
                    If your booking isn’t going ahead, you might be able to get
                    a refund - you can{' '}
                    <a
                      title="If a stadium stops trading or goes out of business"
                      href="/consumer/somethings-gone-wrong-with-a-purchase/if-a-stadium-stops-trading-or-goes-out-of-business/"
                    >
                      find out how to try and get your money back if a stadium
                      stops trading
                    </a>
                    .
                  </p>
                  <a href="/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/">
                    Contact the Citizens Advice consumer helpline
                  </a>{' '}
                  for help working out if you can get a refund for a voucher.
                  <h2
                    dir="ltr"
                    id="h-if-your-refund-is-taking-longer-than-expected"
                    className="js-ref"
                  >
                    If your refund is taking longer than expected
                  </h2>
                  <p dir="ltr">
                    You should contact the stadium and ask how long the refund
                    will take. It’s worth telling them if you need the money
                    urgently - for example, if you’ve lost your job.
                  </p>
                  <p dir="ltr">
                    If the refund takes longer than the stadium said it would,
                    you can{' '}
                    <a
                      title="Find out how to solve a problem with a business or seller"
                      href="/consumer/get-more-help/Solve-an-ongoing-consumer-problem/"
                    >
                      find out how to complain
                    </a>
                    .
                  </p>
                  <h2
                    dir="ltr"
                    id="h-if-the-stadium-says-they-won-t-give-you-a-refund"
                    className="js-ref"
                  >
                    If the stadium says they won’t give you a refund
                  </h2>
                  <p dir="ltr">
                    If you have play insurance, check if you can make a claim.
                    You could read the terms and conditions or contact the
                    insurer. You might also be covered by your home insurance or
                    your bank account insurance.
                  </p>
                  <p dir="ltr">
                    If you claim on your insurance, you might have to pay:&nbsp;
                  </p>
                  <ul>
                    <li dir="ltr">an excess fee</li>
                  </ul>
                  <ul>
                    <li className="_mce_tagged_br" dir="ltr">
                      more for insurance in the future
                    </li>
                  </ul>
                  <p dir="ltr">
                    If you booked something for someone else, you might not be
                    able to claim it back unless they’re named on your insurance
                    policy.
                  </p>
                  <p dir="ltr">
                    You could&nbsp;
                    <a href="/consumer/somethings-gone-wrong-with-a-purchase/getting-your-money-back-if-you-paid-by-card-or-paypal/">
                      check if you can get a refund if you paid by card or
                      Paypal
                    </a>
                    &nbsp;instead.
                  </p>
                  <p dir="ltr">
                    You can try to get a refund from your card provider at the
                    same time as starting your insurance claim. You can only get
                    a refund from one of them - if you keep the money from both
                    it could be fraud.
                  </p>
                  <a href="/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/">
                    Contact the Citizens Advice consumer helpline
                  </a>{' '}
                  if you need more help - a trained adviser can give you advice
                  over the phone or by email.
                  <h2 dir="ltr" id="h-if-you-need-more-help" className="js-ref">
                    If you need more help
                  </h2>
                  <p>
                    You can{' '}
                    <a href="/consumer/get-more-help/Solve-an-ongoing-consumer-problem/">
                      check how to deal with a stadium that won’t give you a
                      refund
                    </a>
                    .
                  </p>
                  <p>
                    You can also&nbsp;
                    <a href="/consumer/get-more-help/if-you-need-more-help-about-a-consumer-issue/">
                      contact the Citizens Advice consumer helpline
                    </a>
                    &nbsp;- a trained adviser can give you advice over the phone
                    or by email.
                  </p>
                  <p>
                    If a stadium has treated you unfairly, you can report them
                    to Trading Standards. You can&nbsp;
                    <a href="/consumer/get-more-help/report-to-trading-standards/">
                      check how to report a stadium to Trading Standards
                    </a>
                    .
                  </p>
                  <div className="callout--alert">
                    <p>
                      As a charity, we rely on your support to help millions of
                      people solve their problems each year.{' '}
                      <a
                        href="https://citizensadvice.enthuse.com/website"
                        rel="external"
                        className="a-external"
                      >
                        Please donate
                      </a>{' '}
                      if you can to help us continue our work.
                    </p>
                  </div>
                </div>
              </div>

              <button
                className="btn btnFind secondModal w-100 my-2"
                onClick={(evt) => close_modal()}
              >
                {' '}
                Continue{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
