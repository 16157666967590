import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  set_selected_city_areas,
  set_selected_country_locations,
  set_selected_location,
  update_filter,
} from '../redux/ducks/malaeb';
import axios from 'axios';
import { SERVER_URL, SPORTS } from '../Constants';
import { getCurrentCoords, getGeoLocation } from '../helpers';

export default function StadiumSearch(props) {
  const dispatch = useDispatch();
  const history2 = useHistory();

  const {
    country: selected_country,
    city_areas,
    selected_location,
    filters,
    //countries_list, top_rated_stadiums,
    country_locations,
  } = useSelector((state) => state.malaeb);

  const nearest_location_name = selected_location?.name || '';

  useEffect(() => {
    async function getInitData() {
      axios
        .get(`${SERVER_URL}/api/v2/locations/${selected_country.id}`)
        .then((res) => {
          // await axios.get(`${SERVER_URL}/api/v2/locations/4`).then(res=>{
          let country_locations = res.data.data;
          // console.log({country_locations});
          dispatch(set_selected_country_locations(country_locations));
        });
    }
    getInitData();
  }, [dispatch, selected_country]);

  function change_selected_city(evt) {
    let selected_id = evt.target.value;
    let selected_country_location = country_locations.find(
      (item) => item.id == selected_id
    );
    let selected_city_areas =
      selected_country_location && selected_country_location.areas
        ? selected_country_location.areas
        : [];
    dispatch(set_selected_location(selected_country_location));
    dispatch(set_selected_city_areas(selected_city_areas));
  }
  function change_selected_city_area(evt) {
    let selected_id = evt.target.value;
    let selected_country_location = country_locations.find(
      (item) => item.id == selected_id
    );
    dispatch(set_selected_location(selected_country_location));
  }

  function changeFilter(filter_name, evt) {
    let val;
    if (evt && evt.target) val = evt.target.value;
    else val = evt;
    if (filter_name == 'search') {
      dispatch(update_filter({ search: val }));
    } else if (filter_name == 'sport_type_id') {
      let sport = SPORTS.find((item) => item.id == val);
      dispatch(update_filter({ sport }));
    } else if (filter_name == 'coords') {
      let { lat, lng } = val;
      dispatch(update_filter({ lat, lng }));
    }
  }

  function doAFilter() {
    dispatch(update_filter({ filter_counter: ++filters.filter_counter }));
    history2.push('list');
  }

  function getLocation() {
    getCurrentCoords().then((coords) => {
      if (coords && coords.latitude) {
        changeFilter('coords', { lat: coords.latitude, lng: coords.longitude });
        setTimeout(() => {
          history2.push('list');
        }, 200);
      } else {
        getGeoLocation().then((data) => {
          changeFilter('coords', { lat: data.latitude, lng: data.longitude });
          setTimeout(() => {
            history2.push('list');
          }, 200);
        });
      }
    });
  }

  return (
    <div className="sectionFirst">
      <div className="overlay">
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="text-white fw-bold pt-5">
                Booking Sport Venues Has Never Been Easier
              </h1>
              <p className="text-white pb-4">
                Find Fields Near You In{' '}
                {nearest_location_name || selected_country.name}
              </p>
              <div>
                <div className="d-sm-flex justify-content-center">
                  <div className="d-sm-flex bg-white findForm mb-4">
                    <div className="px-lg-1">
                      <input
                        type="text"
                        id="search_text"
                        className="form-control mt-2 border-0"
                        placeholder="Search with stadium name..."
                        value={filters.search}
                        onChange={(evt) => changeFilter('search', evt)}
                      />
                    </div>

                    {/* <div className="input-group px-lg-1">
                        <input type="text" className="form-control border-0" placeholder="Search with stadium name..." value={filters.search} onChange={evt=>changeFilter("search",evt)} aria-describedby="search_text"/>
                        <div className="input-group-append">
                            <span className="fa fa-search fa-2x mt-2 px-1" id="search_text"></span>
                        </div>
                        </div> 
                    */}

                    <div className="dropSport text-start">
                      <a
                        className="nav-link dropdown-toggle ps-2 w-100"
                        href="/#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={`images/sports/${filters.sport.id}.png`}
                          className="ps-2"
                          alt="img"
                        />{' '}
                        <span className="text-dark d-inline d-sm-none float-end">
                          {filters.sport.name_en}
                        </span>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {SPORTS.map((item, key) => (
                          <li
                            key={key}
                            onClick={(evt) =>
                              changeFilter('sport_type_id', item.id)
                            }
                          >
                            <a className="dropdown-item" href="/#">
                              <img
                                src={`images/sports/${item.id}.png`}
                                alt="img"
                              />{' '}
                              <span className="text-dark">{item.name_en}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="<!--me-lg-2 px-lg-3-->">
                      {/* <select className="form-select border-0" aria-label="Default select example" value={selected_location.id || ''} onChange={change_selected_city}> */}
                      <select
                        id="CITY"
                        className="form-select border-0"
                        aria-label="Default select example"
                        onChange={change_selected_city}
                      >
                        <option value="" defaultValue>
                          Select Your City
                        </option>
                        {country_locations.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {city_areas && city_areas.length > 0 ? (
                      <div className="<!--me-lg-2 px-lg-3--> border-start">
                        <select
                          className="form-select border-0"
                          aria-label="Default select example"
                          value={selected_location.id || ''}
                          onChange={change_selected_city_area}
                        >
                          <option value="" defaultValue>
                            Select Your Area
                          </option>
                          {city_areas.map((item, key) => (
                            <option key={key} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <a
                        href="/#"
                        className="btn bg-white textG border-0 mt-1 mb-1 text-start"
                        onClick={getLocation}
                      >
                        <img
                          src="images/Position.png"
                          className="imgPosition"
                          alt="img"
                        />{' '}
                        <span className="d-md-none">Detect My Location</span>
                      </a>
                    )}
                    <div>
                      <button
                        className="btn btnFind mt-1 mb-1"
                        onClick={(evt) => doAFilter()}
                      >
                        {' '}
                        Find{' '}
                        <span
                          className="fa fa-search fa-lg px-1"
                          id="search_icon"
                        ></span>{' '}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p
                  className="pt-2 text-white clickable"
                  onClick={(evt) => doAFilter()}
                >
                  Or explore Malaeb
                </p>
                <img
                  alt="img"
                  src="images/arrow.png"
                  className="img-fluid mx-auto mb-5 clickable"
                  onClick={(evt) => doAFilter()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
