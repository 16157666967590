import moment from 'moment';
import { COUNTRIES } from './Constants';
import { store } from 'react-notifications-component';

export function generated_days_list(days_limit = 14) {
  let dates_list = [];
  for (let i = 0; i < days_limit; i++) {
    let day_after = moment().add(i, 'days');
    dates_list.push({
      date_format: day_after.format('YYYY-MM-DD'),
      month_day: day_after.format('DD MMM'),
      day_name: i == 0 ? 'Today' : day_after.format('ddd'),
    });
  }
  return dates_list;
}

export function uuidv4() {
  let crypto;
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function getStorage(str) {
  let data = window.localStorage?.getItem('malaeb.' + str);
  if (data) return JSON.parse(data);
  return false;
}
export function setStorage(str, data) {
  window.localStorage?.setItem('malaeb.' + str, JSON.stringify(data));
  return data;
}
export function removeStorage(str) {
  if (typeof str == 'string') window.localStorage?.removeItem('malaeb.' + str);
  else
    for (let i of str) {
      window.localStorage?.removeItem('malaeb.' + i);
    }
}

export const time_values = [
  '01:00 am',
  '01:30 am',
  '02:00 am',
  '02:30 am',
  '03:00 am',
  '03:30 am',
  '04:00 am',
  '04:30 am',
  '05:00 am',
  '05:30 am',
  '06:00 am',
  '06:30 am',
  '07:00 am',
  '07:30 am',
  '08:00 am',
  '08:30 am',
  '09:00 am',
  '09:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '00:30 pm',
  '01:00 pm',
  '01:30 pm',
  '02:00 pm',
  '02:30 pm',
  '03:00 pm',
  '03:30 pm',
  '04:00 pm',
  '04:30 pm',
  '05:00 pm',
  '05:30 pm',
  '06:00 pm',
  '06:30 pm',
  '07:00 pm',
  '07:30 pm',
  '08:00 pm',
  '08:30 pm',
  '09:00 pm',
  '09:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm',
];

export function getCurrentCoords() {
  navigator.geolocation.watchPosition(
    function (position) {
      console.log('location permission allowed');
    },
    function (error) {
      if (error.code == error.PERMISSION_DENIED)
        console.log('location permission denied');
    }
  );

  return new Promise((resolve, reject) => {
    if (navigator.permissions) {
      // Permission API is implemented
      navigator.permissions
        .query({
          name: 'geolocation',
        })
        .then((permission) => {
          // is geolocation granted?
          if (permission.state === 'granted')
            navigator.geolocation.getCurrentPosition((pos) =>
              resolve(pos.coords)
            );
          else {
            store.addNotification({
              title: 'Warning!!',
              message: `Couldn't get ur accurate location, Please Allow us permission to your location for more accurate results!`,
              type: 'warning',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true,
              },
            });
            resolve(null);
          }
        });
    } else {
      store.addNotification({
        title: 'Warning!!',
        message: `Couldn't get ur accurate location, Please Allow us permission to your location for more accurate results!`,
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      resolve(null);

      // Permission API was not implemented
      reject(new Error('Permission API is not supported'));
    }
  });
}

export function getGeoLocation() {
  return fetch(
    'https://ipgeolocation.abstractapi.com/v1/?api_key=1769b53f310c4dcb8e3370a100cbf6f7'
  ).then((response) => response.json());
}

export async function getCurrentCountry() {
  await fetch(
    'https://ipgeolocation.abstractapi.com/v1/?api_key=1769b53f310c4dcb8e3370a100cbf6f7'
  )
    .then((response) => response.json())
    .then((data) => {
      let country_detected =
        COUNTRIES.find((item) => item.code == data.country_code) ||
        COUNTRIES[0];
      setStorage('selected_country', country_detected);
      // console.log({country_detected});
      window.location.reload();
    });
}

export function handleError(error) {
  if (error.response) {
    let errorMsg =  error.response.data?.message || error.response.error || error.response.data.error
    store.addNotification({
      title: 'Something Went Wrong!!',
      message:  errorMsg,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  } else console.error(error);
}

export function modalNav(to) {
  // window.$("#"+from).modal('hide');
  window.$('.modal').modal('hide');
  if (to)
    setTimeout(() => {
      window.$('#' + to).modal('show');
    }, 200);
}

export function refresh(timer = 0) {
  setTimeout(() => {
    window.location.reload();
  }, timer);
}

export function LoaderModule() {
  return (
    <div className="col-12 text-center" style={{ marginTop: '20%' }}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
