import { time_values } from '../../../helpers';
import { SPORTS } from '../../../Constants';

export function RenderSideFilter({
    filters,
    changeFilter,
    doAFilter,
    clearFilter,
    getLocation,
  }) {
    // const dispatch = useDispatch();
  
    return (
      <div className="d-none d-md-block">
        <h3 className="text-center">
          <img className="me-2" src="images/point.png" alt="img"/>
          <span className="border-bottom">FILTER BY</span>{' '}
          <img className="ms-2" src="images/point.png" alt="img"/>
        </h3>
  
        <p className="pt-4 mb-1 fw-bold">Date</p>
        <div id="datepicker" className="mb-3" />
        {/* <p className="py-2 fw-bold">Time</p> */}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <p className="mt-2 mb-1 fw-bold">Time From</p>
  
              {/* <label htmlFor="from">From</label> */}
              {/* <input id="from" className="form-control" type="time" name='from' /> */}
              <select
                className="form-control text-uppercase"
                value={filters.time}
                onChange={(evt) => {
                  changeFilter('start_from', evt);
                }}
              >
                <option value="">Choose Your Time</option>
                {time_values.map((time, key) => (
                  <option value={time} key={key}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="col">
              <div className="form-group">
                  <label htmlFor="to">To</label>
                  <input id="to" className="form-control" type="time" name='to' />
              </div>
          </div> */}
        </div>
  
        <p className="fw-bold mt-4 mb-1">Choose Sport</p>
        <div className="row pb-4 btnSlot d-md-flex d-none sports_icons" dir="ltr">
          {SPORTS.map((item, key) => (
            <div className="col-4 p-2" title={item.name_en} key={key}>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                autoComplete="off"
              />
              <label
                className={`btn btn-outline-success w-100 ${
                  filters.sport.id == item.id ? 'active' : ''
                }`}
                htmlFor="success-outlined"
                onClick={(evt) => changeFilter('sport_type_id', item.id)}
              >
                <img src={`images/sports/${item.id}.png`} width="40" alt="img"/>
              </label>
            </div>
          ))}
        </div>
  
        <p className="pt-3 mb-1 fw-bold">Match Duration</p>
        <div className="row pb-4 btnSlot d-md-flex d-none" dir="ltr">
          <div className="col-4">
            <input
              type="radio"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.duration == 60 ? 'active' : ''
              }`}
              htmlFor="success-outlined"
              onClick={(evt) => changeFilter('duration', 60)}
            >
              60 Mins
            </label>
          </div>
          <div className="col-4">
            <input
              type="radio"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.duration == 90 ? 'active' : ''
              }`}
              htmlFor="success-outlined2"
              onClick={(evt) => changeFilter('duration', 90)}
            >
              90 Mins
            </label>
          </div>
          <div className="col-4">
            <input
              type="radio"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.duration == 120 ? 'active' : ''
              }`}
              htmlFor="success-outlined3"
              onClick={(evt) => changeFilter('duration', 120)}
            >
              120 Mins
            </label>
          </div>
        </div>
  
        <p className="pt-3 mb-1 fw-bold">Pitch Size</p>
        <div className="row">
          <div className="col-4">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(5) ? 'active' : ''
              }`}
              htmlFor="success-outlined1"
              onClick={(evt) => {
                changeFilter('sizes', 5);
              }}
            >
              5 V 5
            </label>
          </div>
          <div className="col-4">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(6) ? 'active' : ''
              }`}
              htmlFor="success-outlined2"
              onClick={(evt) => {
                changeFilter('sizes', 6);
              }}
            >
              6 V 6
            </label>
          </div>
          <div className="col-4">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(7) ? 'active' : ''
              }`}
              htmlFor="success-outlined3"
              onClick={(evt) => {
                changeFilter('sizes', 7);
              }}
            >
              7 V 7
            </label>
          </div>
          <div className="col-4 mt-2">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(8) ? 'active' : ''
              }`}
              htmlFor="success-outlined4"
              onClick={(evt) => {
                changeFilter('sizes', 8);
              }}
            >
              8 V 8
            </label>
          </div>
          <div className="col-4 mt-2">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(9) ? 'active' : ''
              }`}
              htmlFor="success-outlined5"
              onClick={(evt) => {
                changeFilter('sizes', 9);
              }}
            >
              9 V 9
            </label>
          </div>
          <div className="col-4 mt-2">
            <input
              type="checkbox"
              className="btn-check"
              name="options-outlined"
              autoComplete="off"
            />
            <label
              className={`btn btn-outline-success w-100 ${
                filters.sizes.includes(11) ? 'active' : ''
              }`}
              htmlFor="success-outlined6"
              onClick={(evt) => {
                changeFilter('sizes', 11);
              }}
            >
              11 V 11
            </label>
          </div>
        </div>
  
        <div className="mt-4">
          <p className="fw-bold my-0 mb-1">
            Distance <span className="float-end">{filters.distance} KM</span>
          </p>
          <input
            type="range"
            id="volume"
            name="volume"
            step="10"
            min="20"
            max="200"
            value={filters.distance}
            onChange={(evt) => changeFilter('distance', evt)}
          />
        </div>
        <p className="py-2">
          <a className="textG clickable" onClick={(evt) => getLocation()}>
            <img src="images/location.png" alt="img"/> Use current location
          </a>
        </p>
  
        <div className="row">
          <div className="col-6">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="female_friendly"
                id="female_friendly"
                checked={filters.female_friendly}
                onChange={(evt) => changeFilter('female_friendly')}
              />
              <label className="form-check-label h6" htmlFor="female_friendly">
                {' '}
                Female Friendly{' '}
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="order_by_distance"
                id="order_by_distance"
                checked={filters.order_by_distance}
                onChange={(evt) => changeFilter('order_by_distance')}
              />
              <label className="form-check-label h6" htmlFor="order_by_distance">
                {' '}
                Nearest First{' '}
              </label>
            </div>
          </div>
        </div>
  
        <div className="row mt-4">
          <button
            className="btn btn-outline-dark btn-lg col mx-1"
            onClick={(evt) => clearFilter()}
          >
            Clear
          </button>
          <button
            className="btn btn-success btn-lg col mx-1"
            onClick={(evt) => doAFilter()}
          >
            Filter
          </button>
        </div>
      </div>
    );
  }