import { useHistory } from 'react-router-dom';
import { LoaderModule } from '../../../helpers';

export function RecommendedStadium({ top_rated_stadiums, favoriteStadiums }) {
  let history = useHistory();

  return (
    <>
      {top_rated_stadiums?.length > 0 ? (
        <div className="topStaduims pb-4">
          <div className="container-fluid pt-5 px-0">
            <h3 className="text-center">
              <img className="me-2" src="images/point.png" alt="img" />
              RECOMMENDED <span className="border-bottom">STADIUMS</span>{' '}
              <img className="ms-2" src="images/point.png" alt="img" />
            </h3>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {favoriteStadiums.map(({ stadium }, key) => {
                  return (
                    <div className="swiper-slide" key={key}>
                      <div className="card cardSwipper favorite_stadium">
                        <img
                          src={`${stadium.image_url}small`}
                          className="card-img-top"
                          alt="img"
                        />
                        <div
                          className="card-body clickable"
                          onClick={(evt) => {
                            history.push(`/${stadium.slug}`);
                          }}
                        >
                          <h5>
                            {stadium.title}
                            <span className="float-end stars">
                              <i className="fa fa-star" />{' '}
                              {stadium.average_rating}/5
                            </span>
                          </h5>
                          <p>
                            {stadium.location.country_name},{' '}
                            {stadium.location.location_name_en}
                          </p>
                          <h6>{stadium.pitches.length} Pitches</h6>
                          <button className="btn btnBook w-100">
                            Book from{' '}
                            <span className="textG">
                              {stadium.starting_price} {stadium.currency}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {top_rated_stadiums.map((item, key) => {
                  return (
                    <div className="swiper-slide" key={key}>
                      <div className="card cardSwipper">
                        <img
                          src={`${item.image_url}small`}
                          className="card-img-top"
                          alt="img"
                        />
                        <div
                          className="card-body clickable"
                          onClick={(evt) => {
                            history.push(`${item.slug}`);
                          }}
                        >
                          <h5>
                            {item.title}
                            <span className="float-end stars">
                              <i className="fa fa-star" /> {item.average_rating}
                              /5
                            </span>
                          </h5>
                          <p>
                            {item.location.country_name},{' '}
                            {item.location.location_name_en}
                          </p>
                          <h6>{item.pitches.length} Pitches</h6>
                          <button className="btn btnBook w-100">
                            Book from{' '}
                            <span className="textG">
                              {item.starting_price} {item.currency}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="swiper-button-next">
                <img src="images/Rightcircle.png" alt="img" />
              </div>
              <div className="swiper-button-prev">
                <img src="images/Leftcircle.png" alt="img" />
              </div>
              {/* Add Pagination */}
              <div className="swiper-pagination" />
            </div>
          </div>
        </div>
      ) : (
        <LoaderModule />
      )}
    </>
  );
}
