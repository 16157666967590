import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';
import { SERVER_URL, SPORTS } from '../../Constants';
import { update_filter } from '../../redux/ducks/malaeb';
import moment from 'moment';
import MobileView from '../../components/MobileView';
import {
  ModalPaymentMethods,
  ModalPaymentSuccess,
} from './Components/Payments';
import { ModalBooking } from './Components/ModalBooking';
import { ModalValidateNumber } from './Components/ModalValidateNumber';
import ModalRefundRules from './Components/RefundingModal';
import { store } from 'react-notifications-component';
import { Helmet } from 'react-helmet-async';

export default function Stadium(props) {
  const dispatch = useDispatch();

  const [selected_stadium, setSelected_stadium] = useState({});
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [reviews, set_reviews] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [favoriteStadiums, setFavoriteStadiums] = useState([]);
  const [refreshFavorite, setRefreshFavorite] = useState(0);

  const routeParams = useParams();

  const { filters, SelectedSlot, user_uuid, user_data } = useSelector(
    (state) => state.malaeb
  );

  function init_plugins({ maxDate }) {
    window.$('#datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
      defaultDate: filters.date,
      minDate: 0,
      maxDate: maxDate - 1,
      onSelect: (evt) => {
        console.log('selected date: ', evt);
        changeFilter('date', evt);
      },
    });
    window.$('#mainCarouselControls').carousel({
      interval: 2000,
    });
  }

  useEffect(() => {
    async function getInitData() {
      await axios
        .get(`${SERVER_URL}/api/v2/stadiums/${routeParams.id}`)
        .then((res) => {
          let stadium_data = res.data.data;
          changeFilter('duration', stadium_data.duration_options[0]);
          setSelected_stadium(stadium_data);
          setTimeout(() => {
            init_plugins({
              maxDate: stadium_data.max_date_for_advanced_bookings,
            });
          }, 500);
          axios
            .get(`${SERVER_URL}/api/v2/stadiums/${stadium_data.id}/reviews`)
            .then((res) => {
              console.log({ one: res.data.data });
              set_reviews(res.data.data);
            });
        })
        .catch((error) => {
          store.addNotification({
            title: 'Warning!!',
            message: `Couldn't find this stadium`,
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
              pauseOnHover: true,
            },
          });
          window.location.href = '/';
        });
    }
    getInitData();
  }, []);

  useEffect(() => {
    async function getFavorites() {
      await axios
        .get(`${SERVER_URL}/api/v2/favorites?user_id=${user_uuid}`)
        .then((res) => {
          const stadiums_list = res.data.data.map((item) => item.stadium_id);
          console.log({ stadiums_list });
          setFavoriteStadiums(stadiums_list);
        });
    }
    getFavorites();
  }, [refreshFavorite]);

  function AddStadiumToFavorite(stadium = selected_stadium) {
    if (favoriteStadiums.includes(stadium.id)) {
      axios
        .delete(`${SERVER_URL}/api/v2/favorites`, {
          data: { user_id: user_uuid, stadium_id: stadium.id },
        })
        .then((res) => {
          // window.alert('Stadium Deleted from Favorites');
          setRefreshFavorite(refreshFavorite + 1);
          // window.location.reload();
        });
    } else
      axios
        .post(`${SERVER_URL}/api/v2/favorites`, {
          user_id: user_uuid,
          stadium_id: stadium.id,
        })
        .then((res) => {
          // window.alert('Stadium Added to Favorites');
          setRefreshFavorite(refreshFavorite + 1);
          // window.location.reload();
        });
  }

  console.log({ selected_stadium });
  if (!selected_stadium.id) return <div />;

  const customer_support_number =
    selected_stadium.location.country_id == 3
      ? '+966115206574'
      : '+97332103013';

  // let sports_pitches_array = {};
  // let stadium_sports_array = [];
  // for(let pitch of selected_stadium.pitches){
  // 	for(let sport of pitch.sports){
  // 		if(!sports_pitches_array[sport.id]) sports_pitches_array[sport.id] = [];
  // 		sports_pitches_array[sport.id].push(pitch);
  // 		stadium_sports_array.push(sport.id);
  // 	}
  // }
  // stadium_sports_array = [...new Set(stadium_sports_array)];
  // sports_pitches_array = Object.values(sports_pitches_array);
  // console.log({pitches: selected_stadium.pitches, sports_pitches_array});

  let positive_reviews = [];
  for (let review of reviews) {
    if (review.rating >= 4) {
      positive_reviews.push(review);
    }
  }

  function shareStadium(evt) {
    evt.preventDefault();
    const url = window.location.href;
    if (navigator.share) {
      navigator
        .share({ title: 'Malaeb Stadium', url })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.error('Share API failed');
      navigator.clipboard.writeText(url);
      alert('Copied Stadium Link');
    }
  }

  function changeFilter(filter_name, evt) {
    let val;
    if (evt && evt.target) val = evt.target.value;
    else val = evt;
    console.log({ filter_name, val });
    if (filter_name == 'duration') {
      dispatch(update_filter({ duration: val }));
    }
    if (filter_name == 'sport_type_id') {
      let sport = SPORTS.find((item) => item.id == val);
      dispatch(update_filter({ sport }));
    }
    if (filter_name == 'date') {
      console.log({ val });
      dispatch(update_filter({ date: val }));
    }
    console.log('done');
    // sport_type_id
    // female_friendly
    // sizes
    // search
    // available.date
    // available.time
    // available.duration
  }

  const today = moment().format('YYYY-MM-DD');
  const selected_date = filters.date
    ? moment(filters.date, 'YYYY-MM-DD')
    : moment(today, 'YYYY-MM-DD');
  const show_format = selected_date.format('DD MMM');

  function openBooking() {
    if (!filters.date) changeFilter('date', today);
    setTimeout(() => {
      setShowBookingModal(true);
      setTimeout(() => {
        window.$('#bookingModal').modal('show');
      }, 200);
    }, 100);
  }

  let isFemaleDay = false;
  if (selected_stadium && selected_stadium.female_days.length > 0) {
    const day_name = moment(filters.date, 'YYYY-MM-DD').format('ddd');
    if (
      selected_stadium.female_days.map((item) => item.name).includes(day_name)
    )
      isFemaleDay = true;
  }

  // return (<div></div>);
  // function open_refund_modal() {
  //   window.$('#RefundRulesModal').modal('show');
  // }

  return (
    <>
      <Helmet>
        <title>{selected_stadium.title}</title>
        <meta
          name="description"
          content={selected_stadium.title.slice(0, 100)}
        />
        <meta property="og:title" content={selected_stadium.title} />
        <meta
          property="og:url"
          content={`https://malaebapp.com/${selected_stadium.title}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://example.com/image.jpg"
        ></meta>
        <meta property="og:description" content="Description Here"></meta>

        <link rel="canonical" href="/" />
      </Helmet>
      <div>
        <Header />
        {/* Top Related Stadiums */}
        <div className="topStaduims pb-4">
          <div
            className={`container pt-5 ${isFemaleDay ? 'hasFemaleDays' : ''}`}
          >
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <h3 className="pb-2">{selected_stadium.title}</h3>
                <p>
                  <img src="./images/star.png" alt="img" />
                  <span className="stars fw-bold">
                    {selected_stadium.average_rating}
                  </span>{' '}
                  ({selected_stadium.rated_times} reviews)
                  <span className="ps-lg-5 mx-2">
                    <img src="./images/Combined.png" alt="img" />{' '}
                    {selected_stadium.location.country_name},{' '}
                    {selected_stadium.location.location_name_en}
                  </span>
                </p>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="pb-4 /*d-none d-md-block*/">
                  <button
                    className="btn btnFilter ms-auto float-end"
                    data-bs-toggle="modal"
                    data-bs-target="#modalFilter"
                    onClick={shareStadium}
                  >
                    <img src="./images/share.png" alt="img" /> Share
                  </button>
                  {!favoriteStadiums.includes(selected_stadium.id) ? (
                    <button
                      className="btn btnFilter ms-auto float-end me-2"
                      onClick={(evt) => AddStadiumToFavorite(selected_stadium)}
                    >
                      <img src="./images/heart.png" alt="img" /> Save
                    </button>
                  ) : (
                    <button
                      className="btn btnFilter ms-auto float-end me-2 btnActive"
                      onClick={(evt) => AddStadiumToFavorite(selected_stadium)}
                    >
                      <img src="./images/heart.png" alt="img" /> UnSave
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-7 mb-3">
                {/* Intro Stad */}
                <div className="introStad">
                  <div className="d-flex pt-4">
                    <div className="flex-shrink-0">
                      <img
                        src="./images/tag.png"
                        className="imgTag"
                        alt="img"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3 pt-1">
                      <h5 className="fw-bold"> {selected_stadium.title} </h5>
                      <p className="textVsmall">
                        On Malaeb Since {selected_stadium.created_at_human}
                      </p>
                    </div>
                    <div className="d-none d-md-block">
                      {selected_stadium.facebook_url && (
                        <a
                          target="_blank"
                          href={selected_stadium.facebook_url}
                          className="text-decoration-none"
                        >
                          <img
                            src="./images/facedark.png"
                            className="me-2"
                            alt="img"
                          />
                        </a>
                      )}
                      {selected_stadium.twitter_url && (
                        <a
                          target="_blank"
                          href={selected_stadium.twitter_url}
                          className="text-decoration-none"
                        >
                          <img
                            src="./images/twitterdark.png"
                            className="me-2"
                            alt="img"
                          />
                        </a>
                      )}
                      {selected_stadium.instagram_url && (
                        <a
                          target="_blank"
                          href={selected_stadium.instagram_url}
                          className="text-decoration-none"
                        >
                          <img
                            src="./images/instagramdark.png"
                            className="me-2"
                            alt="img"
                          />
                        </a>
                      )}
                      {selected_stadium.website && (
                        <a
                          target="_blank"
                          href={selected_stadium.website}
                          className="text-decoration-none"
                        >
                          <img
                            src="./images/website.png"
                            width="22"
                            className="me-2"
                            alt="img"
                          />
                        </a>
                      )}

                      {!user_data.id && (
                        <a
                          target="_blank"
                          href={
                            selected_stadium.whatsapp ||
                            `https://wa.me/${customer_support_number}?text=Hello,%20about%20${selected_stadium.title}%20Stadium%20:%20`
                          }
                          className="text-decoration-none"
                        >
                          <img
                            src="./images/whatsapp.png"
                            width="22"
                            className="me-2"
                            alt="img"
                          />
                        </a>
                      )}
                    </div>
                  </div>

                  {selected_stadium.pitch_type && selected_stadium.venue_type && (
                    <p className="p-0 m-0 text-capitalize">
                      <b>Pitch Type:</b> {selected_stadium.pitch_type},{' '}
                      <b>Venue Type:</b> {selected_stadium.venue_type}
                    </p>
                  )}

                  <p className="pb-3">
                    {/* <span className="fw-bold">Opening Hours</span> 09:00 AM - 11:00 PM */}
                    <span className="fw-bold">Opening Hours</span>{' '}
                    {selected_stadium.opening_time} -{' '}
                    {selected_stadium.closing_time}
                    {selected_stadium.female_days.length > 0 && (
                      <small className="femaleDays p-1 float-end">
                        Ladies Days:{' '}
                        {selected_stadium.female_days
                          .map((item) => item.name)
                          .join(', ')}
                      </small>
                    )}
                  </p>
                  <div
                    id="mainCarouselControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner sliderStad">
                      <div className="carousel-item active">
                        <img
                          src={`${selected_stadium.image_url}large`}
                          className="card-img-top"
                          alt="img"
                        />
                      </div>
                      {selected_stadium.images &&
                        selected_stadium.images.map((item, key) => (
                          <div className="carousel-item" key={key}>
                            <img
                              src={`${item}large`}
                              className="card-img-top"
                              alt="img"
                            />
                          </div>
                        ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#mainCarouselControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#mainCarouselControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                {/* Stadium Location */}
                <h5 className="mb-0 pt-4 pb-0">Stadium Location</h5>
                <img src="./images/bgLarge.png" className="bgImg" alt="img" />
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      className="gmap_iframe"
                      width="100%"
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      src={`https://maps.google.com/maps?width=600&height=400&hl=en&t=&z=15&ie=UTF8&iwloc=B&output=embed&q=${selected_stadium.geolat},${selected_stadium.geolng}`}
                    ></iframe>
                  </div>
                  {/* <a href="https://www.google.com/maps/dir/?api=1&origin=34.1030032,-118.41046840000001&destination=34.059808,-118.368152" target="_blank"></a> */}
                </div>
                {/* Available Pitches */}
              </div>

              <div className="col-lg-4 col-md-5">
                <div className="d-block editPicker">
                  <div className="d-none d-md-block">
                    <p className="text-center fw-bold mb-2">
                      Book a Field on {selected_stadium.title}
                    </p>
                    <p className="textSmall text-center pb-4">
                      Select date and duration to show available slots
                    </p>
                    <div id="datepicker" className="mb-3" />
                  </div>
                  {/* Display only in mobile */}
                  <div className="d-block d-md-none mb-3">
                    <h5>Available Slots</h5>
                    <div className="d-flex" onClick={(evt) => openBooking()}>
                      <div className="minSlot text-center w-100 me-2">
                        <img src="./images/Angle-left.png" alt="img" />
                        <span className="textG">{show_format}</span>
                        <img src="./images/Angle-right.png" alt="img" />
                      </div>
                      <div className="minSlot text-center w-100 me-2">
                        <img src="./images/Angle-left.png" alt="img" />
                        <span className="textG">{filters.duration} Mins</span>
                        <img src="./images/Angle-right.png" alt="img" />
                      </div>
                    </div>
                  </div>
                  <p className="pt-3 mb-0 fw-bold d-md-block d-none" dir="ltr">
                    Match Duration
                  </p>
                  <div className="row pb-4 btnSlot d-md-flex d-none" dir="ltr">
                    {selected_stadium.duration_options.includes(60) && (
                      <div className="col-4 mt-3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="success-outlined"
                          autoComplete="off"
                        />
                        <label
                          className={`btn btn-outline-success w-100 ${
                            filters.duration == 60 ? 'active' : ''
                          }`}
                          htmlFor="success-outlined"
                          onClick={(evt) => changeFilter('duration', 60)}
                        >
                          60 Mins
                        </label>
                      </div>
                    )}
                    {selected_stadium.duration_options.includes(90) && (
                      <div className="col-4 mt-3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="success-outlined2"
                          autoComplete="off"
                        />
                        <label
                          className={`btn btn-outline-success w-100 ${
                            filters.duration == 90 ? 'active' : ''
                          }`}
                          htmlFor="success-outlined2"
                          onClick={(evt) => changeFilter('duration', 90)}
                        >
                          90 Mins
                        </label>
                      </div>
                    )}
                    {selected_stadium.duration_options.includes(120) && (
                      <div className="col-4 mt-3">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="success-outlined3"
                          autoComplete="off"
                        />
                        <label
                          className={`btn btn-outline-success w-100 ${
                            filters.duration == 120 ? 'active' : ''
                          }`}
                          htmlFor="success-outlined3"
                          onClick={(evt) => changeFilter('duration', 120)}
                        >
                          120 Mins
                        </label>
                      </div>
                    )}
                  </div>
                  <button
                    className="btn w-100 d-block mx-auto btnFind"
                    onClick={(evt) => openBooking()}
                  >
                    Show Available Slots
                  </button>
                </div>
              </div>

              <div className="container">
                <h5 className="mt-4">Available Pitches</h5>
                <img src="./images/bgLarge.png" className="bgImg" alt="img" />

                {/* <div className="row mx-1" onClick={evt=>openBooking()}> */}
                <div className="row mx-1">
                  {selected_stadium.pitches.length &&
                    selected_stadium.pitches
                      .filter((pitch) => pitch.sports.length)
                      .map((pitch, key2) => (
                        <div
                          className="col btn btn-outline-success py-3 mx-1"
                          key={key2}
                        >
                          <img
                            src={`./images/sports/${pitch.sports[0].id}.png`}
                            width="30"
                            alt="img"
                          />{' '}
                          {pitch.lable} - {pitch.pitch_size} v{' '}
                          {pitch.pitch_size}
                        </div>
                      ))}
                </div>

                {/* Facilities */}
                <div className="mt-4">
                  {/* <h5 className="mb-0">Facilities</h5> */}
                  <h5 className="mb-0">We Offer</h5>
                  <img src="./images/bgSmall.png" className="bgImg" alt="img" />

                  {/* <div className="mb-3" onClick={evt=>openBooking()}> */}
                  <div className="mb-3">
                    <div className="row">
                      {selected_stadium.ball_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/ball.png"
                              width="40"
                              alt="img"
                            />
                            <span className="ml-2"> Ball</span>
                            {selected_stadium.ball_available == 2 && (
                              <small className="">(extra price)</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selected_stadium.bibs_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/shirt.png"
                              width="40"
                              alt="img"
                            />
                            <span className="ml-2"> Shirts</span>
                            {selected_stadium.bibs_available == 2 && (
                              <small className="">(extra price)</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selected_stadium.shower_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/shower.png"
                              width="40"
                              alt="img"
                            />
                            <span className="ml-2"> Showers</span>
                            {selected_stadium.shower_available == 2 && (
                              <small className="">(extra price)</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selected_stadium.washroom_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/toilet.png"
                              width="40"
                              alt="img"
                            />
                            <span className="ml-2"> Toilets</span>
                            {selected_stadium.washroom_available == 2 && (
                              <small className="">(extra price)</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selected_stadium.water_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/water.png"
                              width="40"
                              alt="img"
                            />
                            <span className="ml-1">Water</span>
                            {selected_stadium.water_available == 2 && (
                              <small className="">(extra)</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selected_stadium.washroom_available > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/female.png"
                              width="40"
                              alt="img"
                            />
                            <small className="ml-2"> Female Friendly</small>
                          </div>
                        </div>
                      )}
                      {selected_stadium.female_days.length > 0 && (
                        <div className="col my-1">
                          <div className="facilities">
                            <img
                              src="./images/facilities/female-days.png"
                              width="40"
                              alt="img"
                            />
                            <small className="ml-2"> Ladies Days</small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {selected_stadium.about_us && (
                  <p className="p-0 m-0 my-2 mt-4 text-capitalize nl2br">
                    <b>About Us: </b> {selected_stadium.about_us}
                  </p>
                )}
                {/* <b className="clickable float-end" onClick={evt=>open_refund_modal()}>Refunding Policy</b> */}
                {selected_stadium.owner_note && (
                  <p className="p-0 m-0 my-2 mb-4 text-capitalize nl2br">
                    <b>Owner Notes: </b> {selected_stadium.owner_note}
                  </p>
                )}

                {/* Reviews */}
                {reviews.length > 0 && (
                  <>
                    <div className="reviews mb-3 pt-4">
                      <h5 className="mb-0">Reviews</h5>
                      <img
                        src="./images/bgSmall.png"
                        className="bgImg"
                        alt="img"
                      />
                      <div className="d-flex pb-3 pt-2">
                        <button className="btn btnFilter">
                          <i className="fa fa-star stars fa-2x mx-1" />
                          <span className="txtLarge fw-bold">
                            {selected_stadium.average_rating} / 5
                          </span>
                        </button>
                        <div className="ps-3">
                          {positive_reviews.length && (
                            <h6 className="mb-1 pt-1 d-none d-md-block fw-bold">
                              {positive_reviews.length} Players highly recommend
                              this stadium
                            </h6>
                          )}
                          <p className="mb-0 textSmall d-none d-md-block">
                            Based on {reviews.length} Reviews
                          </p>
                          <p className="mb-0 textSmall pt-2 d-block d-md-none">
                            Based on {reviews.length} Reviews
                          </p>
                          <p className="d-block d-md-none">
                            <a
                              href="/#"
                              className="textG text-decoration-none"
                              onClick={(evt) =>
                                setShowAllReviews(!showAllReviews)
                              }
                            >
                              {' '}
                              Show {!showAllReviews
                                ? 'All'
                                : 'Less'} reviews{' '}
                            </a>
                          </p>
                        </div>
                      </div>

                      <div id="reviews-list">
                        {reviews
                          .filter((item, key) => showAllReviews || key < 3)
                          .map((item, key) => (
                            <div className="card mb-3" key={key}>
                              <div className="card-body">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={item.image_url}
                                      className="imgCircle"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3 pt-1">
                                    <h5 className="fw-bold mb-1">
                                      {item.name}{' '}
                                      <span className="float-end fw-normal textSmall">
                                        {item.time_ago}
                                      </span>
                                    </h5>
                                    <p className="textVsmall">
                                      <img src="./images/star.png" alt="img" />{' '}
                                      5
                                    </p>
                                  </div>
                                </div>
                                <p>{item.review}</p>
                              </div>
                            </div>
                          ))}
                      </div>

                      {reviews.length > 3 && (
                        <div className="row justify-content-center pt-4">
                          <div className="col-lg-6 col-md-8 col-10">
                            <button
                              className="btn w-100 d-block mx-auto btnFind"
                              onClick={(evt) =>
                                setShowAllReviews(!showAllReviews)
                              }
                            >
                              Show {!showAllReviews ? 'All' : 'Less'} reviews
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalRefundRules selected_stadium={selected_stadium} />
        {showBookingModal && (
          <>
            <ModalBooking
              isFemaleDay={isFemaleDay}
              selected_stadium={selected_stadium}
              filters={filters}
              changeFilter={changeFilter}
            />
          </>
        )}
        {SelectedSlot.pitch && (
          <>
            <ModalValidateNumber
              selected_stadium={selected_stadium}
              filters={filters}
              changeFilter={changeFilter}
            />
            <ModalPaymentMethods
              selected_stadium={selected_stadium}
              filters={filters}
              changeFilter={changeFilter}
              AddStadiumToFavorite={AddStadiumToFavorite}
            />
            <ModalPaymentSuccess selected_stadium={selected_stadium} />
          </>
        )}

        <div className="container">
          {' '}
          <MobileView />{' '}
        </div>
        <Footer />
      </div>
    </>
  );
}
