export function NearbyAreas({ country_locations, changeFilter }) {
  return (
    <>
      {country_locations?.length > 0 && (
        <div>
          <h3 className="text-center">
            <img className="me-2" src="images/point.png" alt="img" />
            NEARBY <span className="border-bottom">AREAS</span>{' '}
            <img className="ms-2" src="images/point.png" alt="img" />
          </h3>
          <div className="row pt-5">
            {country_locations.slice(0, 8).map((item, key) => (
              <div
                className="col-lg-3 col-md-4 col-6 mb-3 clickable"
                key={key}
                onClick={(evt) => changeFilter('setCityCords', item)}
              >
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      src={`images/cities/${key + 1}.png`}
                      className="imgMedia"
                      alt="..."
                    />
                    {/* <img src={`${item.stadiums[0].image_url}small`} className="imgMedia" alt="..." /> */}
                  </div>
                  <div className="flex-grow-1 ms-3 pt-lg-4 pt-md-4 pt-sm-3 pt-1">
                    <h6 className="fw-bold pt-1">{item.name}</h6>
                    <p className="textVsmall">
                      {item.stadiums && item.stadiums.length}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
