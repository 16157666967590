import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from './../../components/Header';
import Footer from './../../components/Footer';
import MobileView from './../../components/MobileView';
import { SERVER_URL, SPORTS } from '../../Constants';
import axios from 'axios';
import {
  set_top_rated_stadiums,
  update_filter,
} from '../../redux/ducks/malaeb';
import StadiumSearch from '../../components/StadiumSearch';
import { getCurrentCountry, LoaderModule } from '../../helpers';
import { Helmet } from 'react-helmet-async';
import { RecommendedStadium } from './Components/RecommendedStadium';
import { NearbyAreas } from './Components/NearbyAreas';
import { BrowseStadiums } from './Components/BrowseStadiums';
import { ReadyToAccept } from './Components/ReadyToAccept';
import { BookYourGame } from './Components/BookYourGame';

function Home(props) {
  const [favoriteStadiums, setFavoriteStadiums] = useState([]);
  const dispatch = useDispatch();

  const {
    country: selected_country,
    top_rated_stadiums,
    country_locations,
    user_uuid,
  } = useSelector((state) => state.malaeb);

  function changeFilter(filter_name, evt) {
    let val;
    if (evt && evt.target) val = evt.target.value;
    else val = evt;

    if (filter_name == 'coords') {
      let { lat, lng } = val;
      dispatch(update_filter({ lat, lng }));
    } else if (filter_name == 'setCityCords') {
      let { geolat: lat, geolng: lng } = val;
      dispatch(update_filter({ lat, lng }));
      props.history.push('/list');
    } else if (filter_name == 'sport_type_id') {
      let sport = SPORTS.find((item) => item.id == val);
      dispatch(update_filter({ sport }));
      props.history.push('/list');
    }
  }

  useEffect(() => {
    async function getInitData() {
      await axios
        .get(`${SERVER_URL}/api/v2/favorites?user_id=${user_uuid}`)
        .then((res) => {
          setFavoriteStadiums(res.data.data);
        });
      await axios
        .get(`${SERVER_URL}/api/v2/stadiums/top-rated/${selected_country.id}`)
        .then((res) => {
          let top_rated_stadiums = res.data.data;
          // console.log(top_rated_stadiums);
          dispatch(set_top_rated_stadiums(top_rated_stadiums));
          init_slider();
        });
    }
    getInitData();
  }, [selected_country]);

  if (!selected_country.id) {
    getCurrentCountry();
    return LoaderModule();
  } else
    return (
      <>
        <Helmet>
          <title> Malaeb - Find & Book Stadiums & Pitches</title>
          <meta name="generator" content="WPML ver:3.6.3 stt:5,1;" />
          <meta
            property="og:site_name"
            content="Malaeb ملاعب – احجز والعب مباريات كرة قدم – Book &amp; Play Footbal"
          />
          <meta property="og:title" content="Home" />
          <meta property="og:url" content="https://malaebapp.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://example.com/image.jpg"></meta>
          <meta property="og:description" content="Description Here"></meta>
          <meta
            name="description"
            content="Malaeb App #1 Booking application in MENA Region. Book a sports pitch or facility around you in Bahrain - Saudi Arabia - Kuwait - United Arab Emirates - Oman & other countries soon."
          />

          <link rel="canonical" href="/" />
        </Helmet>
        <div>
          <Header {...props} />

          {/* Find Best Stadiums */}
          <StadiumSearch />

          {/* Top Related Stadiums */}
          <RecommendedStadium
            top_rated_stadiums={top_rated_stadiums}
            favoriteStadiums={favoriteStadiums}
          />

          {/* Nearby Areas */}
          <div className="container pt-5">
            <NearbyAreas
              country_locations={country_locations}
              changeFilter={changeFilter}
            />

            <MobileView />

            {/* BROWSE STADIUMS BY SIZE */}
            <BrowseStadiums changeFilter={changeFilter} />

            {/* Ready To Accept */}
            <ReadyToAccept />

            {/* Book your Game */}
            <BookYourGame />
          </div>

          <Footer />
        </div>
      </>
    );
}

function init_slider() {
  setTimeout(() => {
    var swiper = new window.Swiper('.swiper-container', {
      slidesPerView: 3,
      spaceBetween: 60,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // autoplay: {
      //     delay: 3000,
      // },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      },
    });
  }, 50);
}

export default Home;
export const title = 'Counter Title';
