import { SPORTS } from '../../../Constants';

export function BrowseStadiums({ changeFilter }) {
  return (
    <>
      <div>
        <h3 className="text-center">
          <img className="me-2" src="images/point.png" alt="img" />
          BROWSE <span className="border-bottom">STADIUMS</span> BY SPORT
          <img className="ms-2" src="images/point.png" alt="img" />
        </h3>
        <div className="row justify-content-center pt-5">
          {SPORTS.map((item, key) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 mb-3 clickable"
              onClick={(evt) => changeFilter('sport_type_id', item.id)}
              key={key}
            >
              <div className="card cardPlayground">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img
                        src={`images/sports/${item.id}.png`}
                        width="60"
                        alt="..."
                      />
                    </div>
                    <div className="flex-grow-1 ms-3 pt-1">
                      <h5>{item.name_en}</h5>
                      <p>{item.stadiums_count} Stadiums</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
