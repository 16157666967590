export function ReadyToAccept() {
  return (
    <>
      <div className="score mt-5">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-5">
            <div className="p-lg-4 pt-md-4 pt-sm-4 pt-4">
              <img
                src="images/soccer.png"
                className="img-fluid ms-3 ms-lg-4 ms-md-2"
                alt="img"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-7">
            <h2 className="pt-lg-5 pt-md-4 pt-sm-5 pt-5 textlarge fw-bold h1">
              Ready To Accept <br />
              New <span className="textG">Challenges</span> ?
            </h2>
            <img
              src="images/Page.png"
              className="ms-auto d-block img-fluid"
              alt="img"
            />
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="pt-4 paddingTop">
              <p className="fw-bold">In Malaeb we have</p>
              <p>
                <span className="textG fw-bold pe-2">+1440</span>
                Registered Stadiums 🏟
              </p>
              <p>
                <span className="textG fw-bold pe-2">+29K</span>Joined Players
                ⚽️
              </p>
              <p>
                <span className="textG fw-bold pe-2">+10K</span>Match Created 🏆
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
