import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './pages/Home/index';
import ListPage from './pages/List/index';
import StadiumPage from './pages/Stadium/index';

function App() {
  return (
    <Switch>
      <Route
        path="/list"
        render={(routeProps) => <ListPage {...routeProps} />}
      />
      <Route
        exact
        path="/"
        render={(routeProps) => <HomePage {...routeProps} />}
      />
      <Route
        path="/:id"
        render={(routeProps) => <StadiumPage {...routeProps} />}
      />
      <Route path="/" render={(routeProps) => <HomePage {...routeProps} />} />
    </Switch>
  );
}

export default App;
