export function BookYourGame() {
  return (
    <>
      <div className="pt-5">
        <h3 className="text-center">
          <img className="me-2" src="images/point.png" alt="img" />{' '}
          <span className="border-bottom">BOOK</span> YOUR GAME WITHIN MINUTES{' '}
          <img className="ms-2" src="images/point.png" alt="img" />
        </h3>
        <div className="row justify-content-around pt-5">
          <div className="col-lg-3 col-md-3 sm-6 text-md-center text-lg-center mb-3">
            <div className="d-md-block d-flex">
              <div className="bgImage">
                <img
                  src="images/Position.png"
                  className="img-fluid mx-auto d-block imageFixedHeight"
                  alt="img"
                />
              </div>
              <div>
                <h3 className="fw-bold pt-4 pt-lg-3">Find</h3>
                <p className="textSmall">A nearby Stadium</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 sm-6 text-md-center text-lg-center mb-3">
            <div className="d-md-block d-flex">
              <div className="bgImage">
                <img
                  src="images/calendar.png"
                  className="img-fluid mx-auto d-block imageFixedHeight"
                  alt="img"
                />
              </div>
              <div>
                <h3 className="fw-bold pt-4 pt-lg-3">Select</h3>
                <p className="textSmall">Date, time, duration and pitch size</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 sm-6 text-md-center text-lg-center mb-3">
            <div className="d-md-block d-flex">
              <div className="bgImage">
                <img
                  src="images/Creditcard.png"
                  className="img-fluid mx-auto d-block imageFixedHeight"
                  alt="img"
                />
              </div>
              <div>
                <h3 className="fw-bold pt-4 pt-lg-3">Pay</h3>
                <p className="textSmall">The booking amount online</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
