import React from 'react';

export default function MobileView(props) {
  return (
    <div className="jumbo">
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <img src="images/iphone.png" className="imgAbsolute" alt="img" />
        </div>
        <div className="col-lg-7 col-md-8 text-center">
          <h2 className="fw-bold textLg pt-md-5 pt-5 pb-0 mb-0 h1">
            <img
              src="images/linesLeft.png"
              className="me-3 d-none d-lg-inline"
              alt="img"
            />{' '}
            Start Organizing Your <span className="textG">Games</span>{' '}
            <img
              src="images/linesRight.png"
              className="ms-3 d-none d-lg-inline"
              alt="img"
            />
          </h2>
          <img
            src="images/border.png"
            className="ms-auto d-block me-5 d-none d-lg-block"
            alt="img"
          />
          <p className="pt-md-5 pt-3 clearfix">Download the App Now</p>
          <p className="text-center pb-4">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.malaebapp.app"
              rel="noreferrer"
            >
              <img
                src="images/googleplay.png"
                className="me-2 imgSocial"
                alt="img"
              />
            </a>
            <a
              target="_blank"
              href="https://itunes.apple.com/us/app/malaeb-app/id1094201670?ls=1&mt=8"
              rel="noreferrer"
            >
              <img src="images/appstore.png" className="imgSocial" alt="img" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
