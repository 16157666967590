import { combineReducers, createStore, applyMiddleware } from "redux";
import counterReducer from "./ducks/counter";
import userReducer from "./ducks/user";
import malaebReducer from "./ducks/malaeb";

// import createSagaMiddleware from "redux-saga";
// import { watcherSaga } from "./sagas/rootSaga";

const reducer = combineReducers({
	counter: counterReducer,
	user: userReducer,
	malaeb: malaebReducer,
});

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];

const store = createStore(reducer);

// sagaMiddleware.run(watcherSaga);
export default store;
