import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { COUNTRIES, LANGS } from '../Constants';
import {
  set_selected_city_areas,
  set_selected_country,
  set_selected_location,
} from '../redux/ducks/malaeb';

export default function Header(props) {
  const dispatch = useDispatch();

  const pathname = props.location?.pathname;
  const { country: selected_country, lang: selected_lang } = useSelector(
    (state) => state.malaeb
  );

  function change_selected_country(evt, country) {
    dispatch(set_selected_location({}));
    dispatch(set_selected_city_areas([]));
    dispatch(set_selected_country(country));
    setTimeout(() => {
      window.$('#CITY').val('');
    }, 500);
  }

  return (
    <header>
      <nav className="navbar editNavbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="./images/logo.png"
              alt="imageLogo"
              className="img-fluid"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse pt-xl-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle ps-2"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={`./images/flags/${selected_country.code}.svg`}
                    className="ps-2 mr-1"
                    width="30"
                    alt="img"
                  />{' '}
                  {selected_country.name}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {COUNTRIES.map((item) => (
                    <li key={item.code}>
                      <a
                        className="dropdown-item"
                        href="/#"
                        onClick={(evt) => change_selected_country(evt, item)}
                      >
                        <img
                          src={`./images/flags/${item.code}.svg`}
                          width="30"
                          className="m-2"
                          alt="img"
                        />{' '}
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item me-lg-3">
                <Link
                  to="/"
                  className={`nav-link ${pathname === '/' ? 'active' : ''}`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item me-lg-3">
                <Link
                  to="/list"
                  className={`nav-link ${pathname === '/list' ? 'active' : ''}`}
                >
                  Stadiums
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link textGreen"
                  target="_blank"
                  href="http://go.malaebapp.com/rsa"
                  rel="noreferrer"
                >
                  For Stadiums Owners
                </a>
              </li>
              <li className="nav-item">
                <span className="badge badgeLanguage">
                  {selected_lang.code === 'en' ? LANGS[0].name : LANGS[1].name}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
