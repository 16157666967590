import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import StadiumSearch from '../../components/StadiumSearch';
import axios from 'axios';
import { SERVER_URL, SPORTS } from '../../Constants';
import { clear_filters, update_filter } from '../../redux/ducks/malaeb';
import { getCurrentCoords, getGeoLocation, LoaderModule } from '../../helpers';
import { RenderSideFilter } from './Components/RenderSideFilter';
import { ModalMobileFilter } from './Components/ModalMobileFilter';
import { Helmet } from 'react-helmet-async';

function List(props) {
  const dispatch = useDispatch();

  const [stadiums_list, setStadiums_list] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const {
    country: selected_country,
    selected_location,
    filters,
  } = useSelector((state) => state.malaeb);

  function init_plugins() {
    window.$('#datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
      defaultDate: filters.date,
      minDate: 0,
      maxDate: 90,
      onSelect: (evt) => {
        console.log('selected date: ', evt);
        changeFilter('date', evt);
      },
    });

    window.$('#slider-range').slider({
      min: 0,
      max: 100,
      values: [20],
      slide: function (event, ui) {
        window.$('#amount').val(ui.values + '  KMs');
      },
    });
    window
      .$('#amount')
      .val(window.$('#slider-range').slider('values', 0) + '  KMs');
    // $( "#amount" ).val( "kms" + $( "#slider-range" ).slider( "values", 0 ) +
    // " - kms" );
  }

  useEffect(() => {
    setTimeout(() => {
      init_plugins();
    }, 2000);
  }, []);

  useEffect(() => {
    async function getInitData() {
      setLoading(true);
      let passed_obj = {
        app_type: 'web',
        page,
        limit: 10,
        filters: {
          country_id: selected_country.id,
        },
      };
      console.log({ filters });
      passed_obj.filters.available = {};
      if (filters.sizes.length > 0) passed_obj.filters.sizes = filters.sizes;
      if (filters.search) passed_obj.filters.search = filters.search;
      if (filters.sport && filters.sport.id)
        passed_obj.filters.sport_type_id = filters.sport.id;
      if (filters.duration) passed_obj.filters.duration = filters.duration;
      if (filters.female_friendly)
        passed_obj.filters.female_friendly = filters.female_friendly;
      if (filters.order_by_distance)
        passed_obj.filters.order_by_distance = filters.order_by_distance;
      if (filters.date) passed_obj.filters.available.date = filters.date;
      if (filters.time) passed_obj.filters.available.time = filters.time;
      if (selected_location.id)
        passed_obj.filters.locations = [selected_location.id];
      if (filters.lat && filters.lng) {
        delete passed_obj.filters.locations;
        passed_obj.filters.location_coords = {
          lat: filters.lat,
          lng: filters.lng,
          distance: filters.distance,
        };
      }

      if (filters.duration)
        passed_obj.filters.available.duration = filters.duration;
      if (Object.values(passed_obj.filters.available).length == 0)
        delete passed_obj.filters.available;
      // sport_type_id
      // female_friendly
      // sizes
      // search
      // available.date
      // available.time
      // available.duration

      await axios
        .post(`${SERVER_URL}/api/v2/stadiums/search`, passed_obj)
        .then((res) => {
          let stadiums_list = res.data.data;
          console.log(stadiums_list);
          setStadiums_list(stadiums_list);
          setLoading(false);
          setTimeout(() => {
            const list_elm = window.document.querySelector(
              '#stadiums-main-list'
            );
            if (list_elm) list_elm.scrollIntoView(true);
            window.scroll(0, 500);
            // if(list_elm) list_elm.scroll(500);
          }, 100);
        });
    }
    getInitData();
  }, [page, filters.filter_counter]);

  function changeFilter(filter_name, evt) {
    let val;
    if (evt && evt.target) val = evt.target.value;
    else val = evt;
    console.log({ filter_name, val });
    if (filter_name == 'start_from') {
      dispatch(update_filter({ time: val }));
    } else if (filter_name == 'date') {
      dispatch(update_filter({ date: val }));
    } else if (filter_name == 'duration') {
      dispatch(update_filter({ duration: val }));
    } else if (filter_name == 'sport_type_id') {
      let sport = SPORTS.find((item) => item.id == val);
      dispatch(update_filter({ sport }));
    } else if (filter_name == 'search') {
      dispatch(update_filter({ search: val }));
    } else if (filter_name == 'sizes') {
      let new_sizes_array = [];
      if (filters.sizes.includes(val))
        new_sizes_array = filters.sizes.filter((item) => item != val);
      else {
        filters.sizes.push(val);
        new_sizes_array = filters.sizes;
      }
      dispatch(update_filter({ sizes: new_sizes_array }));
    } else if (filter_name == 'female_friendly') {
      dispatch(update_filter({ female_friendly: !filters.female_friendly }));
    } else if (filter_name == 'order_by_distance') {
      dispatch(
        update_filter({ order_by_distance: !filters.order_by_distance })
      );
    } else if (filter_name == 'distance') {
      dispatch(update_filter({ distance: val }));
    } else if (filter_name == 'coords') {
      let { lat, lng } = val;
      dispatch(update_filter({ lat, lng }));
    }
    console.log('done');
    // sport_type_id
    // female_friendly
    // sizes
    // search
    // available.date
    // available.time
    // available.duration
  }

  function doAFilter() {
    dispatch(update_filter({ filter_counter: ++filters.filter_counter }));
  }

  function clearFilter() {
    dispatch(clear_filters(null));
    setTimeout(() => {
      doAFilter();
    }, 200);
  }

  function getLocation() {
    getCurrentCoords().then((coords) => {
      if (coords && coords.latitude) {
        changeFilter('coords', { lat: coords.latitude, lng: coords.longitude });
        // setTimeout(() => { history2.push('list'); }, 200);
      } else {
        getGeoLocation().then((data) => {
          changeFilter('coords', { lat: data.latitude, lng: data.longitude });
          // setTimeout(() => { history2.push('list'); }, 200);
        });
      }
    });
  }

  return (
    <>
      <Helmet>
        <title> Malaeb - Find & Book Stadiums & Pitches AMR</title>
        <meta name="generator" content="WPML ver:3.6.3 stt:5,1;" />
        <meta
          property="og:site_name"
          content="Malaeb ملاعب – احجز والعب مباريات كرة قدم – Book &amp; Play Footbal"
        />
        <meta property="og:title" content="Home" />
        <meta property="og:url" content="https://malaebapp.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://example.com/image.jpg"
        ></meta>
        <meta property="og:description" content="Description Here"></meta>
        <meta
          name="description"
          content="Malaeb ملاعب – احجز والعب مباريات كرة قدم – Book & Play Footbal" />

        <link rel="canonical" href="/" />
      </Helmet>

      <div>
        <Header {...props} />

        {/* Find Best Stadiums */}
        <StadiumSearch />

        {/* Top Related Stadiums */}
        <div className="topStaduims pb-4">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <div className="d-md-none d-block pb-4">
                  <h1 className="fw-bold">
                    Stadiums
                    <button
                      className="btn btnFilter ms-auto float-end"
                      data-bs-toggle="modal"
                      data-bs-target="#modalFilter"
                    >
                      <img src="images/Settings.png" alt="img" /> Filter
                    </button>
                  </h1>

                  <div>
                    {/* For Mobile only */}

                    <div className="row mb-5 sports_icons w-100 mx-0" dir="ltr">
                      <p className="fw-bold mt-4 mb-1 text-center">
                        Choose A Sport: {filters.sport.name_en}
                      </p>
                      {/* <div className="d-flex overflow-auto py-1 px-1">
                        {SPORTS.map((item,key)=>(
                          <button className={`btn btnDay me-2 mb-3 ${filters.sport.id==item.id?'btnActive':''}`} title={item.name_en} key={key}>
                            <input type="radio" className="btn-check" name="options-outlined" autoComplete="off" />
                            <label className="w-100" htmlFor="success-outlined" onClick={evt=>{changeFilter('sport_type_id',item.id); doAFilter(); }}>
                            <img src={`images/sports/${item.id}.png`} width='40' />
                            </label>
                          </button>
                        ))}
                      </div> */}
                      {SPORTS.map((item, key) => (
                        <div
                          className="col-4 p-2"
                          title={item.name_en}
                          key={key}
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="options-outlined"
                            autoComplete="off"
                          />
                          <label
                            className={`btn btn-outline-success w-100 ${
                              filters.sport.id == item.id ? 'active' : ''
                            }`}
                            htmlFor="success-outlined"
                            onClick={(evt) => {
                              changeFilter('sport_type_id', item.id);
                              doAFilter();
                            }}
                          >
                            <img
                              src={`images/sports/${item.id}.png`}
                              width="55"
                              alt="img"
                            />
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="tab-content" id="stadiums-main-list">
                      <div
                        className="tab-pane fade show active"
                        id="pills-allSports"
                        role="tabpanel"
                        aria-labelledby="pills-allSports-tab"
                      >
                        {loading ? (
                          <LoaderModule />
                        ) : !stadiums_list.length ? (
                          <h3>No Stadiums With these filters</h3>
                        ) : (
                          stadiums_list.map((stadium, key) => {
                            return (
                              <div
                                className="card mb-4 cardList"
                                key={key}
                                onClick={(evt) =>
                                  props.history.push(`/${stadium.slug}`)
                                }
                              >
                                <div className="row g-0">
                                  <div className="col-md-4 position-relative">
                                    <img
                                      src={stadium.image_url + `small`}
                                      className="card-img-top"
                                      alt="img"
                                    />
                                    {stadium.customizable_pitch_text && (
                                      <div className="stadium_listing_offer">
                                        {stadium.customizable_pitch_text}
                                      </div>
                                    )}
                                    {stadium.latest_discount.id && (
                                      <div className="stadium_listing_offer">
                                        {stadium.latest_discount.title_en}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-8">
                                    <div className="card-body">
                                      <h5>
                                        {stadium.title}
                                        {stadium.average_rating && (
                                          <span className="float-end stars">
                                            <i className="fa fa-star" />{' '}
                                            {stadium.average_rating}/5
                                          </span>
                                        )}
                                      </h5>
                                      <p>
                                        <small
                                          title={`${stadium.location.country_name}, ${stadium.location.location_name_en} - ${stadium.location_info?.city}`}
                                        >
                                          {stadium.location_info?.area}
                                        </small>

                                        {stadium.female_days.length > 0 && (
                                          <small className="femaleDays p-1 float-end">
                                            Ladies Days:{' '}
                                            {stadium.female_days
                                              .map((item) => item.name)
                                              .join(', ')}
                                          </small>
                                        )}

                                        {stadium.distance > 0 && (
                                          <small
                                            className="badge badge-dark label-dark mx-1 p-1 text-black-50"
                                            style={{
                                              border: '.5px solid #ccc',
                                            }}
                                          >
                                            {stadium.distance} KM Away
                                          </small>
                                        )}
                                      </p>
                                      <div className="row d-flex d-md-none">
                                        <div className="col-8">
                                          {stadium.pitches.length &&
                                            stadium.pitches
                                              .filter(
                                                (pitch) => pitch.sports.length
                                              )
                                              .map((pitch, key2) => (
                                                <span
                                                  className="badge text-dark border mx-1 p-0 px-1 p-1"
                                                  key={key2}
                                                >
                                                  <img
                                                    src={`./images/sports/${pitch.sports[0].id}.png`}
                                                    width="15"
                                                    alt="img"
                                                  />{' '}
                                                  {pitch.pitch_size} v{' '}
                                                  {pitch.pitch_size}
                                                </span>
                                              ))}
                                        </div>
                                        <div className="col-4 text-end">
                                          <p className="mb-0">Book From</p>
                                          <p>
                                            <span className="fontLg mt-0 pt-0">
                                              {stadium.starting_price}
                                            </span>{' '}
                                            {stadium.currency}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <RenderSideFilter
                  filters={filters}
                  changeFilter={changeFilter}
                  doAFilter={doAFilter}
                  clearFilter={clearFilter}
                  getLocation={getLocation}
                />
              </div>

              <div className="col-lg-8 col-md-7 d-none d-md-block">
                {/* Display none in mobile */}
                {loading ? (
                  <LoaderModule />
                ) : !stadiums_list.length ? (
                  <h3>No Stadiums With these filters</h3>
                ) : (
                  stadiums_list.map((stadium, key) => {
                    return (
                      <div
                        className="card mb-4 cardList d-none d-md-flex clickable"
                        key={key}
                        onClick={(evt) =>
                          props.history.push(`/${stadium.slug}`)
                        }
                      >
                        <div className="row g-0">
                          <div className="col-md-4 position-relative">
                            <img
                              src={stadium.image_url + `small`}
                              className="card-img-top"
                              alt="img"
                            />
                            {stadium.customizable_pitch_text && (
                              <div className="stadium_listing_offer">
                                {stadium.customizable_pitch_text}
                              </div>
                            )}
                            {stadium.latest_discount.id && (
                              <div className="stadium_listing_offer">
                                {stadium.latest_discount.title_en}
                              </div>
                            )}
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <h5>
                                {stadium.title}
                                {stadium.average_rating && (
                                  <span className="float-end stars">
                                    <i className="fa fa-star" />{' '}
                                    {stadium.average_rating}/5
                                  </span>
                                )}
                              </h5>
                              <p>
                                <small
                                  title={`${stadium.location.country_name}, ${stadium.location.location_name_en} - ${stadium.location_info?.city}`}
                                >
                                  {stadium.location_info?.area}
                                </small>

                                {stadium.female_days.length > 0 && (
                                  <small className="femaleDays p-1 float-end">
                                    Ladies Days:{' '}
                                    {stadium.female_days
                                      .map((item) => item.name)
                                      .join(', ')}
                                  </small>
                                )}

                                {stadium.distance > 0 && (
                                  <small
                                    className="badge badge-dark label-dark mx-1 p-1 text-black-50"
                                    style={{ border: '.5px solid #ccc' }}
                                  >
                                    {stadium.distance} KM Away
                                  </small>
                                )}
                              </p>
                              <h6 className="d-none d-md-block">
                                {stadium.pitches.length} Pitches
                                {stadium.pitches.length &&
                                  stadium.pitches
                                    .filter((pitch) => pitch.sports.length)
                                    .map((pitch, key2) => (
                                      <span
                                        className="badge text-dark border mx-1 p-0 px-1 p-1"
                                        key={key2}
                                      >
                                        <img
                                          src={`./images/sports/${pitch.sports[0].id}.png`}
                                          width="20"
                                          alt="img"
                                        />{' '}
                                        {pitch.pitch_size} v {pitch.pitch_size}
                                      </span>
                                    ))}
                              </h6>
                              <button className="btn btnBook w-100 d-none d-md-block">
                                Book from{' '}
                                <span className="textG">
                                  {stadium.starting_price} {stadium.currency}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>

              {loading == false && (
                <div className="row mt-2">
                  {page != 1 && (
                    <div className="col">
                      <button
                        className={`btn btn-outline-success w-100`}
                        onClick={(evt) => setPage(page - 1)}
                      >
                        {' '}
                        Previous{' '}
                      </button>
                    </div>
                  )}
                  {stadiums_list.length > 9 && (
                    <>
                      <div className="col">
                        <p className="text-center pt-2 bold">Page {page}</p>
                      </div>
                      <div className="col">
                        <button
                          className="btn btnNext btn-outline-success w-100"
                          onClick={(evt) => setPage(page + 1)}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
        <ModalMobileFilter
          filters={filters}
          changeFilter={changeFilter}
          doAFilter={doAFilter}
          clearFilter={clearFilter}
          getLocation={getLocation}
        />
      </div>
    </>
  );
}

export default List;
export const title = 'Counter Title';
