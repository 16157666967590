import { generated_days_list, time_values } from '../../../helpers';
const dates_list = generated_days_list();

export function ModalMobileFilter({
    filters,
    changeFilter,
    doAFilter,
    clearFilter,
    getLocation,
  }) {
    // const dispatch = useDispatch();
  
    return (
      <div
        className="modal fade"
        id="modalFilter"
        tabIndex={-1}
        aria-labelledby="modalFilter"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title textG" id="modalFilter">
                Filters
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                <div className="form-group mb-2">
                  <label htmlFor="date" className="fw-bold mb-0">
                    Date Schedule
                  </label>
  
                  <div className="d-flex overflow-auto py-1 px-1">
                    {dates_list.map((item, key2) => (
                      <button
                        className={`btn btnDay me-2 mb-3 ${
                          item.date_format == filters.date ? 'btnActive' : ''
                        }`}
                        key={key2}
                        onClick={(evt) => {
                          changeFilter('date', item.date_format);
                        }}
                      >
                        <p className="mb-0 text-center">{item.day_name}</p>
                        <p className="mb-0">{item.month_day}</p>
                      </button>
                    ))}
                  </div>
                </div>
  
                <p className="pt-0 fw-bold my-0 mt-4">Start Time</p>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      {/* <input id="start_time" className="form-control" type="time" name='start_time' /> */}
                      <select
                        className="form-control text-uppercase"
                        value={filters.time}
                        onChange={(evt) => {
                          changeFilter('start_from', evt);
                        }}
                      >
                        <option value="">Choose Your Time</option>
                        {time_values.map((time, key) => (
                          <option value={time} key={key}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
  
                <p className="mb-0 fw-bold mt-4">Match Duration</p>
                <div className="row" dir="ltr">
                  <div className="col-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.duration == 60 ? 'active' : ''
                      }`}
                      htmlFor="success-outlined"
                      onClick={(evt) => changeFilter('duration', 60)}
                    >
                      60 Mins
                    </label>
                  </div>
                  <div className="col-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.duration == 90 ? 'active' : ''
                      }`}
                      htmlFor="success-outlined2"
                      onClick={(evt) => changeFilter('duration', 90)}
                    >
                      90 Mins
                    </label>
                  </div>
                  <div className="col-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.duration == 120 ? 'active' : ''
                      }`}
                      htmlFor="success-outlined3"
                      onClick={(evt) => changeFilter('duration', 120)}
                    >
                      120 Mins
                    </label>
                  </div>
                </div>
  
                <p className="mb-0 fw-bold mt-4">Pitch Size</p>
                <div className="row">
                  <div className="col-4">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(5) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined1"
                      onClick={(evt) => {
                        changeFilter('sizes', 5);
                      }}
                    >
                      5 V 5
                    </label>
                  </div>
                  <div className="col-4">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(6) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined2"
                      onClick={(evt) => {
                        changeFilter('sizes', 6);
                      }}
                    >
                      6 V 6
                    </label>
                  </div>
                  <div className="col-4">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(7) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined3"
                      onClick={(evt) => {
                        changeFilter('sizes', 7);
                      }}
                    >
                      7 V 7
                    </label>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(8) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined4"
                      onClick={(evt) => {
                        changeFilter('sizes', 8);
                      }}
                    >
                      8 V 8
                    </label>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(9) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined5"
                      onClick={(evt) => {
                        changeFilter('sizes', 9);
                      }}
                    >
                      9 V 9
                    </label>
                  </div>
                  <div className="col-4 mt-2">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="options-outlined"
                      autoComplete="off"
                    />
                    <label
                      className={`btn btn-outline-success w-100 ${
                        filters.sizes.includes(11) ? 'active' : ''
                      }`}
                      htmlFor="success-outlined6"
                      onClick={(evt) => {
                        changeFilter('sizes', 11);
                      }}
                    >
                      11 V 11
                    </label>
                  </div>
                </div>
  
                <div className="mt-4">
                  <p className="fw-bold my-0 mb-1">
                    Distance{' '}
                    <span className="float-end">{filters.distance} KM</span>
                  </p>
                  <input
                    type="range"
                    id="volume"
                    name="volume"
                    step="10"
                    min="20"
                    max="200"
                    value={filters.distance}
                    onChange={(evt) => changeFilter('distance', evt)}
                  />
                </div>
                <p className="py-2">
                  <a className="textG clickable" onClick={(evt) => getLocation()}>
                    <img src="images/location.png" alt="img"/> Use current location
                  </a>
                </p>
  
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="female_friendly"
                        id="female_friendly"
                        checked={filters.female_friendly}
                        onChange={(evt) => changeFilter('female_friendly')}
                      />
                      <label
                        className="form-check-label h6"
                        htmlFor="female_friendly"
                      >
                        {' '}
                        Female Friendly{' '}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="order_by_distance"
                        id="order_by_distance"
                        checked={filters.order_by_distance}
                        onChange={(evt) => changeFilter('order_by_distance')}
                      />
                      <label
                        className="form-check-label h6"
                        htmlFor="order_by_distance"
                      >
                        {' '}
                        Nearest First{' '}
                      </label>
                    </div>
                  </div>
                </div>
  
                <div className="row mt-2 mb-2">
                  <button
                    className="btn btn-outline-dark btn-lg col mx-1"
                    onClick={(evt) => {
                      clearFilter();
                      window.$('#modalFilter').modal('hide');
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-success btn-lg col mx-1"
                    onClick={(evt) => {
                      doAFilter();
                      window.$('#modalFilter').modal('hide');
                    }}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }