import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import { setUserData } from '../../../redux/ducks/malaeb';
import moment from 'moment';
import { handleError, modalNav, refresh } from '../../../helpers';
import { store } from 'react-notifications-component';

export function ModalPaymentMethods({
  selected_stadium,
  filters,
  AddStadiumToFavorite,
}) {
  const dispatch = useDispatch();
  const { SelectedSlot, user_data } = useSelector((state) => state.malaeb);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false);
  const [coupon, setCoupon] = useState({ code: '' });
  const [booking, setBooking] = useState({});
  const [browser, setBrowser] = useState({});
  const currency = selected_stadium.currency;
  // console.log({SelectedSlot});

  const HEADER = {
    headers: {
      Authorization: `Bearer ${user_data.token}`,
      'Content-Type': 'application/json',
    },
  };

  async function choosePaymentMethod() {
    AddStadiumToFavorite();
    console.log({ SelectedSlot });

    if (selectedPaymentMethod == 'cash_on_site') {
      const passed_obj = {
        booking_status: 2,
        match_date: filters.date,
        match_time: SelectedSlot.time,
        match_end_time: SelectedSlot.end_time,
        match_duration: filters.duration,
        pitch_id: SelectedSlot.pitch.id,
        payment_method: 1,
        app_type: 'web',
      };
      await axios
        .post(`${SERVER_URL}/api/v2/booking`, passed_obj, HEADER)
        .then((res) => {
          let booking = res.data.data;
          console.log({ booking });
          axios
            .put(
              `${SERVER_URL}/api/v2/booking/${booking.booking_id}`,
              { user_id: user_data.id },
              HEADER
            )
            .then((res) => {
              modalNav('paymentSuccessModal');
            })
            .catch(handleError);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            store.addNotification({
              title: 'Something Went Wrong!!',
              message: `Your Aren't Authenticated.`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true,
              },
            });
            remove_user();
          } else handleError(error);
        });
    } else if (selectedPaymentMethod == 'credit_card') {
      const passed_obj = {
        booking_status: 2,
        match_date: filters.date,
        match_time: SelectedSlot.time,
        match_end_time: SelectedSlot.end_time,
        match_duration: filters.duration,
        pitch_id: SelectedSlot.pitch.id,
        payment_method: 5,
        app_type: 'web',
      };
      await axios
        .post(`${SERVER_URL}/api/v2/booking`, passed_obj, HEADER)
        .then(async (res) => {
          let booking = res.data.data;
          setBooking(booking);
          modalNav('upfrontAndCoupon');
        })
        .catch((error) => {
          if (error.response.status == 401) {
            store.addNotification({
              title: 'Something Went Wrong!!',
              message: `Your Aren't Authenticated.`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true,
              },
            });
            remove_user();
          } else handleError(error);
        });
    } else if (selectedPaymentMethod == 'apple_pay') {
      const passed_obj = {
        booking_status: 2,
        match_date: filters.date,
        match_time: SelectedSlot.time,
        match_end_time: SelectedSlot.end_time,
        match_duration: filters.duration,
        pitch_id: SelectedSlot.pitch.id,
        payment_method: 5,
        app_type: 'web',
      };
      await axios
        .post(`${SERVER_URL}/api/v2/booking`, passed_obj, HEADER)
        .then(async (res) => {
          let booking = res.data.data;
          await axios
            .get(`${SERVER_URL}/api/v2/browser-detect`)
            .then(async (res) => {
              let browser_analitics = res.data;
              // console.log({browser_analitics});
              setBrowser({ ...browser_analitics });
              setBooking(booking);
              modalNav('upfrontAndCoupon');
            })
            .catch(handleError);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            store.addNotification({
              title: 'Something Went Wrong!!',
              message: `Your Aren't Authenticated.`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true,
              },
            });
            remove_user();
          } else handleError(error);
        });
    } else {
      store.addNotification({
        title: 'Something Went Wrong!!',
        message: `Nothing selected`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  function close_modal({ open_user_modal, open_payment_modal }) {
    if (open_payment_modal) {
      modalNav('PaymentMethods');
      return;
    }
    if (open_user_modal) modalNav('VerifyNumberModal');
    else modalNav('bookingModal');
  }
  function remove_user() {
    // removeStorage('user_data'); //this will remove from session too
    dispatch(setUserData({}));
    close_modal({ open_user_modal: true });
  }

  function apply_coupon(reset = false) {
    console.log({ booking, coupon });
    if (!booking.booking_id) return;
    axios
      .post(
        `${SERVER_URL}/api/v2/bookings/${booking.booking_id}/coupon`,
        { code: coupon.code },
        HEADER
      )
      .then((res) => {
        let coupon_data = res.data;
        console.log('we are here', { ...coupon, ...coupon_data });
        // console.log({coupon});
        setCoupon({ ...coupon, ...coupon_data });
      })
      .catch(handleError);
  }

  let apple_pay_payment_link =
    booking && booking.booking_id
      ? `${SERVER_URL}/payment/interface?id=${booking.booking_id}&processor=apple_pay`
      : null;
  function pay_amount(options = {}) {
    if (!booking.booking_id) return;
    // if(booking->)
    if (selectedPaymentMethod == 'apple_pay') {
      options.processor = 'apple_pay';
      if (browser && !browser.is_safari) options.copyLink = true;
    }
    // let payment_url = `${SERVER_URL}/payment/interface?id=${booking.booking_id}&processor=tap_vm${SelectedSlot.upfront_price && options.pay_upfront_amount ? '&upfront_full=true&upfront=1&upfront_price='+SelectedSlot.upfront_price : ''}`;
    let payment_url = `${SERVER_URL}/payment/interface?id=${
      booking.booking_id
    }&processor=${options.processor || 'tap_vm'}${
      !options.pay_upfront_amount ? '&upfront_full=true' : ''
    }`;
    // console.log({booking}, {payment_url});

    if (options.copyLink) {
      navigator.clipboard.writeText(payment_url);
      store.addNotification({
        title: 'Warning!!',
        message: `Apple Pay works only on safari, Copied Payment Link.`,
        type: 'warning',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    } else {
      window.open(payment_url);
      // window.alert("This Booking is pending on payment, Please refresh once you finish the payment process.");
      let conf = window.confirm(
        'This Booking is pending on payment, Did You Pay ?'
      );
      if (conf == true) {
        store.addNotification({
          title: 'Success!!',
          message: `Thanks, You will receive a confirmation SMS notification If the payment was confirmed.`,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        axios
          .get(`${SERVER_URL}/api/v2/booking/${booking.booking_id}`, HEADER)
          .then((res) => {
            let new_booking_data = res.data;
            console.log({ new_booking_data });
            if (new_booking_data.payment_paid_status) {
              modalNav('paymentSuccessModal');
            } else refresh(2000);
          })
          .catch(handleError);
      } else {
        store.addNotification({
          title: 'Warning!!',
          message: `You Still can pay to confirm your booking!.`,
          type: 'warning',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
    }
  }

  let payment_methods = {};
  if (selected_stadium.supported_payment_methods_on_web) {
    selected_stadium.supported_payment_methods_on_web.forEach((i) => {
      if (i == 5 || i == 6) payment_methods.creditcard = true;
      if (i == 1) payment_methods.cash = true;
      if (i == 8) payment_methods.benefitpay_site = true;
      if (i == 9) payment_methods.creditcard_site = true;
      if (i == 11) payment_methods.apple_pay = true;
      if (i == 1 || i == 8 || i == 9) payment_methods.onSite = true;
    });
  }

  return (
    <>
      {/* <div className="modal fade" id="PaymentMethods" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
      <div
        className="modal fade"
        id="upfrontAndCoupon"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0" dir="rtl">
              <h5
                className="modal-title w-100 text-center"
                id="exampleModalLabel"
              >
                Confirm Booking
              </h5>
              <button
                className="bg-white border-0"
                onClick={(evt) => close_modal({ open_payment_modal: true })}
              >
                <img src="./images/left.png" alt="img" />
              </button>
            </div>

            <div className="modal-body">
              <SelectedSlotBox selected_stadium={selected_stadium} />

              <div className="border border-success h5 mb-4 p-0 p-2 rounded-3 text-center">
                {selectedPaymentMethod == 'credit_card' &&
                  'Pay Using Credit Card'}
                {selectedPaymentMethod == 'apple_pay' && 'Pay Using Apple Pay'}
              </div>

              <div className="col-12 mt-3">
                <h6 className="fw-bold">Promo Code</h6>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon"
                    aria-label="Enter Coupon"
                    aria-describedby="basic-addon2"
                    onChange={(evt) => setCoupon({ code: evt.target.value })}
                  />
                  <div className="input-group-append">
                    {coupon.success === undefined ? (
                      <button
                        className="btn btn-outline-success"
                        type="button"
                        onClick={(evt) => apply_coupon()}
                      >
                        Apply
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-danger"
                        type="button"
                        onChange={(evt) => apply_coupon(true)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>
                {coupon.success && (
                  <div className="text-success pl-2 pt-1">
                    {' '}
                    <i className="fa fa-check-circle"></i> Coupon applied
                    successfully{' '}
                  </div>
                )}
                {coupon.success === false && (
                  <div className="text-danger pl-2 pt-1">
                    {' '}
                    <i className="fa fa-ban"></i> Invalid Coupon{' '}
                  </div>
                )}
              </div>

              {coupon.success && (
                <div className="col-12 mt-4">
                  <p className="m-0">
                    Subtotal{' '}
                    <span className="float-end fw-bold">
                      {coupon.price_before} {currency}
                    </span>
                  </p>
                  <p className="m-0">
                    Promo Code Discount{' '}
                    <span className="float-end btn-bold text-success fw-bold">
                      {coupon.price_discount} {currency}
                    </span>
                  </p>
                  <hr className="my-1" />
                  <p className="m-0">
                    Total{' '}
                    <span className="float-end btn-bold fw-bold">
                      {coupon.price_after} {currency}
                    </span>
                  </p>
                </div>
              )}

              {!browser.is_safari && selectedPaymentMethod == 'apple_pay' && (
                <div className="mt-4">
                  <hr className="m-0 my-2 p-0" />
                  <b>Apple pay is only available for Safari on MAC</b>
                  <label htmlFor="safari">
                    Paste this link to safari browser
                  </label>
                  <div className="input-group mb-3">
                    <input
                      value={apple_pay_payment_link}
                      className="form-control"
                      id="apple_pay_link"
                      disabled
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={(evt) =>
                        pay_amount({ pay_upfront_amount: true })
                      }
                    >
                      Copy Link
                    </button>
                  </div>
                  <hr className="m-0 my-2 p-0" />
                </div>
              )}
              {booking.upfront &&
              SelectedSlot.upfront_price &&
              SelectedSlot.upfront_price > 0 ? (
                <div className="col-12">
                  <div className="text-danger py-2 mt-3">
                    {' '}
                    <i className="fa fa-exclamation-triangle"></i> This stadium
                    requires upfront payment to book{' '}
                  </div>

                  <button
                    className="col-12 btn secondModal my-2 m-1 text-capitalize btn-lg btn-block btn-outline-success"
                    onClick={(evt) => {
                      pay_amount({ pay_upfront_amount: true });
                    }}
                  >
                    {' '}
                    Pay Partial Amount {SelectedSlot.upfront_price} {currency}
                  </button>
                  <button
                    className="col-12 btn secondModal my-2 m-1 text-capitalize btn-lg btn-block btn-outline-success"
                    onClick={(evt) => {
                      pay_amount({ pay_upfront_amount: false });
                    }}
                  >
                    {' '}
                    Pay Full Amount{' '}
                    {SelectedSlot.price_after_discount ||
                      SelectedSlot.price}{' '}
                    {currency}
                  </button>
                </div>
              ) : (
                <div className="col-12 mt-3">
                  <button
                    className="col-12 btn secondModal my-2 m-1 text-capitalize btn-lg btn-block btn-outline-success"
                    onClick={(evt) => {
                      pay_amount({ pay_upfront_amount: false });
                    }}
                  >
                    {' '}
                    Pay {SelectedSlot.final_price} {currency}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modalPhone"
        id="PaymentMethods"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0" dir="rtl">
              <h5
                className="modal-title w-100 text-center"
                id="exampleModalLabel"
              >
                Choose Your Payment Method
              </h5>
              <button className="bg-white border-0" onClick={close_modal}>
                <img src="./images/left.png" alt="img" />
              </button>
            </div>

            <div className="modal-body m-3">
              <SelectedSlotBox selected_stadium={selected_stadium} />

              <button
                className="btn btn-block btn-lg btn-outline-danger mb-3 w-100"
                onClick={remove_user}
              >
                Change User:{' '}
                <small className="mx-4">+{user_data.phone_number}</small>
              </button>

              {payment_methods.creditcard && (
                <div
                  className="form-check paymentWay p-3 my-3"
                  onClick={(evt) => setSelectedPaymentMethod('credit_card')}
                >
                  <input
                    className={`form-check-input ${
                      selectedPaymentMethod == 'credit_card' && 'checked'
                    }`}
                    type="radio"
                    name="paymentMethod"
                    id="credit_card"
                    value="credit_card"
                  />
                  <label className="form-check-label" htmlFor="credit_card">
                    {' '}
                    Credit Card{' '}
                  </label>
                </div>
              )}

              {payment_methods.apple_pay && (
                <div
                  className="form-check paymentWay p-3 my-3"
                  onClick={(evt) => setSelectedPaymentMethod('apple_pay')}
                >
                  <input
                    className={`form-check-input ${
                      selectedPaymentMethod == 'apple_pay' && 'checked'
                    }`}
                    type="radio"
                    name="paymentMethod"
                    id="apple_pay"
                    value="apple_pay"
                  />
                  <label className="form-check-label" htmlFor="apple_pay">
                    {' '}
                    Apple Pay{' '}
                  </label>
                </div>
              )}

              {payment_methods.onSite && (
                <div
                  className="form-check paymentWay p-3 my-3"
                  onClick={(evt) => setSelectedPaymentMethod('cash_on_site')}
                >
                  <input
                    className={`form-check-input ${
                      selectedPaymentMethod == 'cash_on_site' && 'checked'
                    }`}
                    type="radio"
                    name="paymentMethod"
                    id="cash_on_site"
                    value="cash_on_site"
                  />
                  <label className="form-check-label" htmlFor="cash_on_site">
                    {' '}
                    On Site{' '}
                    <small className="text-muted">
                      ( {payment_methods.cash && 'Cash'}{' '}
                      {payment_methods.creditcard_site && ' / Credit Card'}{' '}
                      {payment_methods.benefitpay_site && ' / Benefit Pay'} )
                    </small>{' '}
                  </label>
                </div>
              )}

              {/* {(SelectedSlot.upfront_price && selectedPaymentMethod=='credit_card') ? 
												(
													<div className='row'>
													<button className="col btn btnFind secondModal my-2 m-1 text-capitalize btn-lg" onClick={evt=>{choosePaymentMethod({pay_upfront_amount:true})}} > Upfront Amount {SelectedSlot.upfront_price} {selected_stadium.currency}</button>
													<button className="col btn btnFind secondModal my-2 m-1 text-capitalize btn-lg" onClick={evt=>{choosePaymentMethod({pay_upfront_amount:false})}} > Full Price {SelectedSlot.final_price} {selected_stadium.currency}</button>
													</div>
												)
												: (<button className="btn btnFind secondModal w-100 my-2"
												onClick={evt=>{choosePaymentMethod()}}
												> Confirm My Booking </button>)
											} */}

              <button
                className="btn btnFind secondModal w-100 my-2"
                onClick={(evt) => {
                  choosePaymentMethod();
                }}
              >
                {' '}
                Confirm My Booking{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ModalPaymentSuccess({ selected_stadium }) {
  const { SelectedSlot, filters } = useSelector((state) => state.malaeb);
  const pitch = SelectedSlot.pitch;
  const timeslot_date = moment(SelectedSlot.date, 'YYYY-MM-DD');
  const show_format = timeslot_date.format('ddd DD MMM YYYY');

  return (
    <div
      className="modal fade px-0 show"
      id="paymentSuccessModal"
      tabIndex={-1}
      aria-labelledby="paymentSuccessModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header border-0" dir="rtl">
            <h5
              className="modal-title w-100 text-center"
              id="exampleModalLabel"
            >
              Booking Confirmed
            </h5>
            <button
              type="button"
              className="bg-white border-0"
              data-bs-dismiss="modal"
            >
              <img src="./images/left.png" alt="img" />
            </button>
          </div>
          <div className="modal-body">
            <img
              src="./images/paymentSuccess.png"
              className="d-block mx-auto img-fluid my-2"
              width="80"
              alt="img"
            />
            <h5 className="text-center fw-bold my-3">
              Stadium Booked successfully!
            </h5>
            {/* <p className="text-center d-block d-md-none">Thanks for your booking. You will receive a confirmation on mobile number and email, Enjoy your game!</p> */}
            <div className="bgPayment p-3 mt-2 mb-2">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img
                    src="./images/tag.png"
                    className="img-fluid imgTag"
                    alt="img"
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6 className="pt-1 fw-bold">{selected_stadium.title}</h6>
                  <p>
                    <img
                      src={`./images/sports/${pitch.sports[0].id}.png`}
                      width="30"
                      alt="img"
                    />{' '}
                    {pitch.lable} - {pitch.pitch_size} v {pitch.pitch_size}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-6">
                  <p className="mb-0">Date</p>
                  <h6 className="fw-bold">{show_format}</h6>
                </div>
                {/* Appear only in mobile */}
                <div className="col-lg-4 col-6">
                  <p className="mb-0">Match Time</p>
                  <h6 className="fw-bold">{SelectedSlot.time_am_pm}</h6>
                </div>
                {/* Appear only in desktop */}
                <div className="col-lg-4">
                  <p className="mb-0">Duration</p>
                  <h6 className="fw-bold">{SelectedSlot.duration} Mins</h6>
                </div>
              </div>
            </div>
            <p className="d-none d-md-block">
              Amount{' '}
              <span className="float-end fw-bold">
                {selected_stadium.currency} {SelectedSlot.final_price}
              </span>
            </p>
            {/* <p className="textSmall text-center d-none d-md-block">Thanks for your booking. You will shortly recieve a confirmation SMS to your phone. </p> */}
            <button
              className="btn btnFind w-100 my-2"
              onClick={(evt) => modalNav('')}
            >
              {' '}
              Done{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectedSlotBox({ selected_stadium }) {
  const { SelectedSlot, filters } = useSelector((state) => state.malaeb);
  const pitch = SelectedSlot.pitch;
  const selected_date = moment(filters.date, 'YYYY-MM-DD');
  const show_format = selected_date.format('ddd DD MMM');

  if (!SelectedSlot.pitch_id) return <div></div>;
  return (
    <div className="bgPayment p-3 mb-2">
      <div className="d-flex">
        <div className="flex-shrink-0">
          <img src="./images/tag.png" className="img-fluid imgTag" alt="img" />
        </div>
        <div className="flex-grow-1 ms-3">
          <h6 className="pt-1 fw-bold">{selected_stadium.title}</h6>
          <p>
            <img
              src={`./images/sports/${pitch.sports[0].id}.png`}
              width="30"
              alt="img"
            />{' '}
            {pitch.lable} - {pitch.pitch_size} v {pitch.pitch_size}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-6 text-center">
          <p className="mb-0">Date</p>
          <h6 className="fw-bold">{show_format}</h6>
        </div>
        {/* Appear only in mobile */}
        <div className="col-lg-4 col-6 d-md-none d-block">
          <p className="mb-0">Match Time</p>
          <h6 className="fw-bold">{SelectedSlot.time_am_pm}</h6>
        </div>
        {/* Appear only in desktop */}
        <div className="col-lg-4 d-none d-md-block text-center">
          <p className="mb-0">Start Time</p>
          <h6 className="fw-bold">{SelectedSlot.time_am_pm}</h6>
        </div>
        {/* Appear only in desktop */}
        <div className="col-lg-4 d-none d-md-block text-center">
          <p className="mb-0">Duration</p>
          <h6 className="fw-bold">{filters.duration} Mins</h6>
        </div>
      </div>
    </div>
  );
}
